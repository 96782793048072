import Drawer from '@material-ui/core/Drawer'
import React, { useMemo } from 'react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import cs_logo from '../../../../assets/images/cs_short_logo_white.svg'
import cs_logo_text from '../../../../assets/images/cs_text_white.svg'
import lr_logo from '../../../../assets/images/lr_short_logo.svg'
import lr_logo_text from '../../../../assets/images/lr_text.svg'
import Button from '@material-ui/core/Button/Button'
import { drawerTop, listItem, listItemLabel, listItemOpen, useStyles } from './NavigationDrawerStyles'
import StorageIcon from '@material-ui/icons/StorageOutlined'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooksOutlined'
import { useLocation } from 'react-router-dom'
import { Add, Dashboard, Description, Group, LocalOffer, People, Person, Pool, Storefront } from '@material-ui/icons'
import { NavigationDrawerProps, NavigationEntry, NavigationEntrySetInfo, NavigationLabel } from './types'
import { NavigationEntrySet } from './NavigationEntrySet'
import { getNavigationEntry } from './getNavigationEntry'
import { ROUTES } from '../../../helpers/routes'
import { getTokens, hasUserRoles } from '../../../helpers/keycloakService'
import { Brand, environment } from '../../../helpers/environment'

const masterUserEntries: (NavigationEntry | NavigationLabel | NavigationEntrySetInfo)[] = [
  { Icon: Dashboard, text: 'objects.dashboard', link: ROUTES.home },
  { text: 'objects.licenses' },
  // {
  //   text: 'objects.licenses',
  //   icon: LibraryBooksIcon,
  //   entries: [
  //     { Icon: Add, text: 'licenses.grantLicense', link: ROUTES.grantLicense },
  //     { Icon: StorageIcon, text: 'licenses.grantedLicenses', link: ROUTES.licenses },
  //   ],
  // },
  {
    text: 'objects.licensePools',
    icon: Pool,
    entries: [
      { Icon: Add, text: 'customers.addCustomer', link: ROUTES.addLicensePool },
      { Icon: StorageIcon, text: 'objects.overview', link: ROUTES.licensePools },
    ],
  },
  {
    text: 'objects.licenseTypes',
    icon: LocalOffer,
    entries: [
      { Icon: Add, text: 'customers.addCustomer', link: ROUTES.addLicenseType },
      { Icon: StorageIcon, text: 'objects.overview', link: ROUTES.licenseTypes },
    ],
  },
  // { text: 'objects.contacts' },
  // {
  //   text: 'objects.merchants',
  //   icon: Storefront,
  //   entries: [
  //     { Icon: Add, text: 'merchants.addMerchant', link: ROUTES.addMerchant },
  //     { Icon: StorageIcon, text: 'merchants.myMerchants', link: ROUTES.merchants },
  //   ],
  // },
  // {
  //   text: 'objects.customers',
  //   icon: People,
  //   entries: [
  //     { Icon: Add, text: 'customers.addCustomer', link: ROUTES.addCustomer },
  //     { Icon: StorageIcon, text: 'customers.myCustomers', link: ROUTES.customers },
  //   ],
  // },
  // { text: 'objects.administration' },
  // { Icon: Description, text: 'objects.journal', link: ROUTES.journal },
  { text: 'objects.userManagement' },
  { Icon: Group, text: 'objects.groups', link: ROUTES.groups },
  { Icon: Person, text: 'objects.users', link: ROUTES.users },
]

const mandantEntries: (NavigationEntry | NavigationLabel | NavigationEntrySetInfo)[] = [
  { Icon: Dashboard, text: 'objects.dashboard', link: ROUTES.home },
  { text: 'objects.licenses' },
  {
    text: 'objects.licenses',
    icon: LibraryBooksIcon,
    entries: [
      { Icon: Add, text: 'licenses.grantLicense', link: ROUTES.grantLicense },
      { Icon: StorageIcon, text: 'licenses.grantedLicenses', link: ROUTES.licenses },
    ],
  },
  { Icon: Pool, text: 'objects.licensePools', link: ROUTES.licensePools },
  { Icon: LocalOffer, text: 'objects.licenseTypes', link: ROUTES.licenseTypes },
  { text: 'objects.contacts' },
  {
    text: 'objects.merchants',
    icon: Storefront,
    entries: [
      { Icon: Add, text: 'merchants.addMerchant', link: ROUTES.addMerchant },
      { Icon: StorageIcon, text: 'merchants.myMerchants', link: ROUTES.merchants },
    ],
  },
  {
    text: 'objects.customers',
    icon: People,
    entries: [
      { Icon: Add, text: 'customers.addCustomer', link: ROUTES.addCustomer },
      { Icon: StorageIcon, text: 'customers.myCustomers', link: ROUTES.customers },
    ],
  },
  { text: 'objects.administration' },
  { Icon: Description, text: 'objects.journal', link: ROUTES.journal },
]

const standardEntries: (NavigationEntry | NavigationLabel | NavigationEntrySetInfo)[] = [
  { Icon: Dashboard, text: 'objects.dashboard', link: ROUTES.home },
  { text: 'objects.licenses' },
  {
    text: 'objects.licenses',
    icon: LibraryBooksIcon,
    entries: [
      { Icon: Add, text: 'licenses.grantLicense', link: ROUTES.grantLicense },
      { Icon: StorageIcon, text: 'licenses.grantedLicenses', link: ROUTES.licenses },
      { Icon: StorageIcon, text: 'objects.activations', link: ROUTES.licenseInstances },
    ],
  },
  { Icon: Pool, text: 'objects.licensePools', link: ROUTES.licensePools },
  { Icon: LocalOffer, text: 'objects.licenseTypes', link: ROUTES.licenseTypes },
  { text: 'objects.contacts' },
  {
    text: 'objects.merchants',
    icon: Storefront,
    entries: [
      { Icon: Add, text: 'merchants.addMerchant', link: ROUTES.addMerchant },
      { Icon: StorageIcon, text: 'merchants.myMerchants', link: ROUTES.merchants },
    ],
  },
  {
    text: 'objects.customers',
    icon: People,
    entries: [
      { Icon: Add, text: 'customers.addCustomer', link: ROUTES.addCustomer },
      { Icon: StorageIcon, text: 'customers.myCustomers', link: ROUTES.customers },
    ],
  },
]

export const NavigationDrawer: React.FC<NavigationDrawerProps> = React.memo(({ onDrawerAction, onLinkClicked }) => {
  const [open, setOpen] = React.useState(true)
  const classes = useStyles()
  const location = useLocation()
  const { t } = useTranslation('common')

  const [token] = getTokens()
  const usedEntries: (NavigationEntry | NavigationLabel | NavigationEntrySetInfo)[] = useMemo(() => {
    const roles = hasUserRoles(token)
    return roles.master ? masterUserEntries : roles.mandant ? mandantEntries : standardEntries
  }, [token])

  const handleDrawerOpen = (): void => {
    setOpen(true)
    onDrawerAction(true)
  }

  const handleDrawerClose = (): void => {
    setOpen(false)
    onDrawerAction(false)
  }

  const activeLink: string = useMemo(() => {
    const selected = usedEntries.filter(
      (e) => Object.keys(e).includes('link') && location.pathname.includes((e as NavigationEntry).link),
    )
    const activeLink = selected.length ? (selected[selected.length - 1] as NavigationEntry).link : ''
    if (activeLink) {
      return activeLink
    }
    for (let i = 0; i < usedEntries.length; i++) {
      const e = usedEntries[i]
      if (Object.keys(e).includes('entries')) {
        const set = e as NavigationEntrySetInfo
        const searchElement = set.entries.find((e) => location.pathname.includes(e.link))
        if (searchElement) {
          return searchElement.link
        }
      }
    }

    return ''
  }, [location, usedEntries])

  let logo = cs_logo
  let logo_text = cs_logo_text
  if (environment.BRAND === Brand.Neutral) {
    logo = lr_logo
    logo_text = lr_logo_text
  }

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
          [classes.paper]: true,
        }),
      }}
    >
      <div style={drawerTop}>
        <Button
          className={classes.drawerButton}
          variant="text"
          size={'large'}
          startIcon={
            <>
              {open ? (
                <ChevronLeftIcon className={classes.drawerText} />
              ) : (
                <ChevronRightIcon className={classes.drawerText} />
              )}
            </>
          }
          onClick={open ? handleDrawerClose : handleDrawerOpen}
        >
          <img alt="logo" src={logo} height="36" />
          {open && <img style={{ marginLeft: 6, marginTop: 10 }} alt="logo" src={logo_text} height="10" />}
        </Button>
      </div>
      <List style={{ paddingTop: 50 }}>
        {usedEntries.map((element) => {
          const isEntry = Object.keys(element).includes('link')
          const isEntrySet = Object.keys(element).includes('entries')
          if (isEntry) {
            const entry = element as NavigationEntry
            const selected = activeLink === entry.link
            return getNavigationEntry(classes, selected, entry, onLinkClicked, open, t)
          } else if (isEntrySet) {
            const entrySet = element as NavigationEntrySetInfo
            return (
              <NavigationEntrySet
                key={`set-${entrySet.text}`}
                text={entrySet.text}
                entries={entrySet.entries}
                drawerOpen={open}
                icon={entrySet.icon}
                activeLink={activeLink}
                onLinkClicked={onLinkClicked}
              />
            )
          } else {
            return (
              <ListItem
                disableRipple
                button
                key={element.text}
                style={open ? { ...listItemOpen, ...listItemLabel } : { ...listItem, ...listItemLabel }}
              >
                {!open ? '' : t(element.text)}
              </ListItem>
            )
          }
        })}
      </List>
    </Drawer>
  )
})
NavigationDrawer.displayName = 'NavigationDrawer'

export default NavigationDrawer
