import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react'
import { Container, Grid } from '@material-ui/core'
import { useTranslation } from '../../../hooks/useTranslation'
import { CsButton, FixedActionBar } from '@csinstruments/cs-react-theme'
import { ArrowRight, CloudDownload, CloudUpload } from '@material-ui/icons'
import { PageHeading } from '../../partials/PageHeading'
import { Text } from '../../partials/Inputs/Text'
import { LicenseMetadata, useActivateLicenseMutation } from '../../../../api/models'
import { useQueryParam } from '../../../hooks/useQueryParam'
import { Alert } from '@material-ui/lab'
import { SingleFileSelectButton } from '../../partials/Inputs/SingleFileSelectButton'
import { usePushDownloadFile } from '../../../hooks/usePushDownloadFile'
import jwt from 'jsonwebtoken'
import { environment } from '../../../helpers/environment'

type LicenseInfoForUUID = {
  iss: string
  license_types: [string]
  license_key: string
  uuid: string
}

type LicenseResponse = {
  uuid?: string
  license_key?: string
  license_names?: string[]
  license_types?: string[]
}

const parseJwt = (token: string): LicenseInfoForUUID => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload) as LicenseInfoForUUID
}

export const ActivateLicense: FC = ({}): ReactElement => {
  const { t } = useTranslation()

  const systemInfoB64 = useQueryParam('systemInfo')
  const [systemInfoFromFile, setSystemInfoFromFile] = useState('')
  const { downloadAction } = usePushDownloadFile()

  const [showFailAlert, setShowFailAlert] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [requestPending, setRequestPending] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [licenseKey, setLicenseKey] = useState('')
  const [activateLicenseMutation] = useActivateLicenseMutation()
  const [activationString, setActivationString] = useState('')

  const [licenseKeyLabel, activateLabel, activateLicenseLabel] = useMemo(() => {
    return [t('licenses.licenseKey') + '*', t('actions.activate'), t('actions.activateLicense')]
  }, [t])

  const systemInfo: LicenseMetadata | undefined = useMemo(
    () =>
      systemInfoB64
        ? JSON.parse(atob(systemInfoB64))
        : systemInfoFromFile
        ? JSON.parse(atob(systemInfoFromFile))
        : undefined,
    [systemInfoB64, systemInfoFromFile],
  )

  const downloadActivationString = useCallback(() => {
    downloadAction(activationString, 'activation.txt')
  }, [activationString, downloadAction])

  const doActivate = useCallback(() => {
    if (!(systemInfo && systemInfo.uuid) || !systemInfo || !licenseKey) {
      setShowFailAlert(true)
      setAlertText(t('licenses.inputIncomplete'))
      return
    }

    setRequestPending(true)
    activateLicenseMutation({
      variables: {
        licenseKey,
        metadata: {
          uuid: systemInfo && systemInfo.uuid ? systemInfo.uuid : '',
          hostname: systemInfo.hostname ? systemInfo.hostname : '',
          licenseNames: systemInfo?.licenseNames || [],
          cpuId: systemInfo?.cpuId || '',
          cpuInfo: systemInfo?.cpuInfo || '',
          motherboardSerialnumber: systemInfo?.motherboardSerialnumber || '',
          mac: systemInfo?.mac || '',
        },
      },
    })
      .then((data) => {
        const activationStringFromServer = data.data?.activateLicense
        if (!activationStringFromServer) {
          console.log('ERROR')
          // this should actually be avoided by the "catch()" below but make sure no error occurs
          setShowFailAlert(true)
          return
        }

        const decoded = jwt.verify(activationStringFromServer, environment.LICENSE_SERVER_PUBLIC_KEY, {
          algorithms: ['RS256'],
        }) as LicenseResponse

        const appNameInLicense = decoded.license_names?.find((licenseName) =>
          decoded.license_types?.find((licenseType) => licenseType === licenseName),
        )

        console.log({ decoded })

        if (!appNameInLicense) {
          console.log('ERROR')
          setShowFailAlert(true)
          const appKeys = decoded.license_names?.map((licenseName) => t(`apps.${licenseName}`)).join(',')
          const licensePoolsForKey = decoded.license_types
            ?.filter((licenseType) => licenseType)
            .map((licenseType) => t(`apps.${licenseType}`))
            .join(',')
          setAlertText(
            `${t('error.wrong_license_key_for_app')}: ${appKeys} -  ${t(
              'error.wrong_license_key_for_pool',
            )}: ${licensePoolsForKey}`,
          )
          return
        }

        setActivationString(activationStringFromServer)
        // success
        setAlertText(systemInfoB64 ? t('licenses.activationSucceeded') : t('licenses.offlineActivationSucceeded'))
        setShowFailAlert(false)
        setShowSuccessAlert(true)
      })
      .catch((e) => {
        setAlertText(t('licenses.keyInvalid'))
        setShowFailAlert(true)
        console.log({ e }, environment)
      })
      .finally(() => {
        setRequestPending(false)
      })
  }, [t, systemInfo, activateLicenseMutation, licenseKey, systemInfoB64])

  const enteredValueIsValid = licenseKey.length === 22

  return (
    <Container style={{ padding: 50 }}>
      <FixedActionBar
        disabled={[requestPending || !systemInfo || !systemInfo.uuid || !enteredValueIsValid]}
        conditions={[!showSuccessAlert]}
        labels={[activateLabel]}
        actions={[doActivate]}
        icons={[ArrowRight]}
        buttonProps={[{ solid: true }]}
      />
      <PageHeading title={activateLicenseLabel} />
      {showSuccessAlert && <Alert severity="success">{alertText}</Alert>}
      {showSuccessAlert && !systemInfoB64 && (
        <Grid item xs={5} style={{ paddingTop: 20 }}>
          <CsButton label={t('licenses.downloadOfflineFile')} onClick={downloadActivationString}>
            <CloudDownload />
          </CsButton>
        </Grid>
      )}
      {showFailAlert && <Alert severity="warning">{alertText}</Alert>}
      {!showSuccessAlert && (
        <Grid container spacing={3} style={{ paddingTop: 20, paddingBottom: 50 }}>
          {!(systemInfo && systemInfo.uuid) && (
            <Grid item xs={5}>
              <SingleFileSelectButton
                acceptedType="text/*"
                label={t('licenses.selectID')}
                changeHandler={(contents) => setSystemInfoFromFile(contents)}
                icon={CloudUpload}
              />
            </Grid>
          )}
          <Text
            label={licenseKeyLabel}
            columns={12}
            changeHandler={(val) =>
              setLicenseKey((old) =>
                old.length < val.length &&
                (val.length === 2 || val.length === 7 || val.length === 12 || val.length === 17)
                  ? val.toUpperCase() + '-'
                  : val.toUpperCase().substr(0, 22),
              )
            }
            value={licenseKey}
          />
        </Grid>
      )}
    </Container>
  )
}
