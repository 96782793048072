import React from 'react'
import ReactDOM from 'react-dom'
import App from './app/App'
import { BrowserRouter } from 'react-router-dom'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'

import common_de from './translations/de/common.json'
import common_en from './translations/en/common.json'
import { ThemeProvider } from '@csinstruments/cs-react-theme'
import { keycloakEventHandler, getTokens, keycloak, setTokens } from './app/helpers/keycloakService'
import { AuthClientTokens } from '@react-keycloak/core'
import { useTranslation } from './app/hooks/useTranslation'
import { environment } from './app/helpers/environment'

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'de',
  resources: {
    en: {
      common: common_en,
    },
    de: {
      common: common_de,
    },
  },
})

const [token, refreshToken] = getTokens()
const InnerComponent = () => {
  const { i18n } = useTranslation()
  const selectedTheme = environment.STAGE === 'neutral' ? 'neutralLight' : 'csLight'
  return (
    <ThemeProvider theme={selectedTheme} language={i18n.language.toLowerCase()}>
      <BrowserRouter>
        <ReactKeycloakProvider
          authClient={keycloak}
          onEvent={keycloakEventHandler}
          onTokens={(tokens: AuthClientTokens) =>
            setTokens(tokens.token ?? '', tokens.idToken ?? '', tokens.refreshToken ?? '')
          }
          initOptions={{ onLoad: 'login-required', token, refreshToken, checkLoginIframe: false }}
        >
          <App />
        </ReactKeycloakProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <InnerComponent />
  </I18nextProvider>,
  document.getElementById('root'),
)
