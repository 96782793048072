import React, { useCallback } from 'react'
import { AppTop } from '@csinstruments/cs-react-theme/dist/components/AppTop/AppTop'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import { getApolloClient } from '../../../helpers/apolloClient'
import { keycloakLogout } from '../../../helpers/keycloakService'
import { useTranslation } from '../../../hooks/useTranslation'
import { Tooltip } from '@material-ui/core'

export const LicenseServerAppTop: React.FC = () => {
  const { t } = useTranslation()
  const logout = useCallback(() => {
    keycloakLogout()
    getApolloClient().resetStore()
  }, [])

  const tooltip = t('actions.logout')

  return (
    <>
      <AppTop>
        <Tooltip title={tooltip}>
          <ExitToAppOutlinedIcon style={{ marginLeft: 10, cursor: 'pointer' }} onClick={logout} />
        </Tooltip>
      </AppTop>
    </>
  )
}

export default LicenseServerAppTop
