import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { PageHeading } from '../../partials/PageHeading'
import { Grid } from '@material-ui/core'
import Search from '../../partials/Inputs/Search'
import { PaginationInfoLimitUpdater } from '../../partials/Pagination/PaginationInfoLimitUpdater'
import {
  LicenseType,
  PaginationParams,
  useDeleteLicenseTypeMutation,
  useLicenseTypesQuery,
} from '../../../../api/models'
import { DataTable } from '../../partials/DataTable/DataTable'
import { LicenseServerPagination } from '../../partials/Pagination/LicenseServerPagination'
import { DataTableColumn, DataTableRow } from '../../partials/DataTable/DataTableTypes'
import { toLocaleDateTime } from '../../../helpers/dateTime'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
import { generatePath, useHistory } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import { getTokens, hasUserRoles } from '../../../helpers/keycloakService'
import { FixedActionBar } from '@csinstruments/cs-react-theme'
import { Add } from '@material-ui/icons'

const typesToRows = (
  licenseTypes:
    | ({ __typename?: 'LicenseType' | undefined } & Pick<
        LicenseType,
        'activation_maximum' | 'date_created' | 'date_deleted' | 'description' | 'id' | 'type_key'
      >)[]
    | undefined,
): DataTableRow[] => {
  if (!licenseTypes) {
    return []
  }

  return licenseTypes.map((lt) => {
    return {
      id: lt.id,
      values: [
        lt.id,
        lt.type_key,
        `${lt.activation_maximum}`,
        lt.description || '',
        `${toLocaleDateTime(lt.date_created)}`,
      ],
    }
  })
}

export const ListLicenseTypes: FC = ({}): ReactElement => {
  const { t } = useTranslation()
  const [paginationData, setPaginationData] = useState<PaginationParams>({ limit: 100, Offset: 0 })
  const [searchTerm, setSearchTerm] = useState('')
  const [deleteMutation] = useDeleteLicenseTypeMutation()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedID, setSelectedID] = useState('')
  const { data, refetch } = useLicenseTypesQuery({
    variables: { paginationParams: paginationData },
  })
  const history = useHistory()

  const [token] = getTokens()
  const masterUser = useMemo(() => {
    return hasUserRoles(token).master
  }, [token])

  const [
    licenseTypesLabel,
    searchLabel,
    idLabel,
    keyLabel,
    maxActivationsLabel,
    descriptionLabel,
    createdAtLabel,
  ] = useMemo(() => {
    return [
      t('objects.licenseTypes'),
      t('actions.search'),
      t('objects.id'),
      t('licenseTypes.key'),
      t('licenses.maximumActivations'),
      t('licenseTypes.description'),
      t('licenseTypes.createdAt'),
    ]
  }, [t])

  const searchTermChanged = useCallback((term: string) => {
    setSearchTerm(term)
  }, [])

  const rows = useMemo(() => typesToRows(data?.licenseTypes.licenseTypes), [data])

  const columns: DataTableColumn[] = useMemo(
    () => [
      { label: idLabel },
      { label: keyLabel },
      { label: maxActivationsLabel, numeric: true },
      { label: descriptionLabel },
      { label: createdAtLabel },
    ],
    [idLabel, keyLabel, maxActivationsLabel, descriptionLabel, createdAtLabel],
  )

  const deletionConfirmed = useCallback(() => {
    deleteMutation({ variables: { id: selectedID } }).then(() => refetch())
  }, [deleteMutation, selectedID, refetch])

  return (
    <>
      <StandardConfirmationModal
        handleClose={() => setModalOpen(false)}
        open={modalOpen}
        text={t('modals.standardText')}
        handleConfirm={deletionConfirmed}
      />
      {masterUser && (
        <FixedActionBar
          labels={[t('actions.add')]}
          actions={[() => history.push(ROUTES.addLicenseType)]}
          icons={[Add]}
          buttonProps={[{ solid: true }]}
        />
      )}
      <PageHeading title={licenseTypesLabel} />
      <Grid container spacing={3} style={{ paddingTop: 20 }}>
        <Grid item xs={12} md={6}>
          <PaginationInfoLimitUpdater paginationParams={paginationData} setPaginationParams={setPaginationData} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Search label={searchLabel} changeFunction={searchTermChanged} />
        </Grid>
        <Grid item xs={12}>
          <DataTable
            columns={columns}
            onButtonClicked={(id, action) => {
              if (action === 'delete') {
                setSelectedID(id)
                setModalOpen(true)
              } else {
                history.push(generatePath(ROUTES.editLicenseType, { id }))
              }
            }}
            filterString={searchTerm}
            rows={rows}
            allowedActions={{ sortable: true, deletable: masterUser, editable: masterUser }}
          />
        </Grid>
        <Grid item xs={12}>
          <LicenseServerPagination
            paginationParams={paginationData}
            setPaginationParams={setPaginationData}
            nTotal={data?.licenseTypes.total || 0}
          />
        </Grid>
      </Grid>
    </>
  )
}
