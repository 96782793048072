import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, TextField, Typography } from '@material-ui/core'
import { Text } from '../../partials/Inputs/Text'
import { useTranslation } from '../../../hooks/useTranslation'
import { PageHeading } from '../../partials/PageHeading'
import { FixedActionBar } from '@csinstruments/cs-react-theme'
import { Forward } from '@material-ui/icons'
import {
  BatchGrantLicense,
  useBatchGrantLicensesMutation,
  useCustomerQuery,
  useLicensePoolsQuery,
  useMerchantQuery,
} from '../../../../api/models'
import { Dropdown } from '../../partials/Inputs/Dropdown'
import { useQueryParam } from '../../../hooks/useQueryParam'
import { ResultAlert } from '../../partials/ResultAlert'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import { convertData, licensePoolEntriesToStrings, licensePoolEntryToString } from './GrantOrUpdateLicense'

type GrantLicenseFormValues = {
  poolStrings: string[]
  customer?: string
  merchant?: string
  maxActivations?: string
  note?: string
  nLicenses?: string
  orderNumber?: string
}

export const BatchGrantLicenses: FC = ({}): ReactElement => {
  const { t } = useTranslation()
  const preselectedMerchant = useQueryParam('merchant')
  const preselectedCustomer = useQueryParam('customer')
  const [showFailAlert, setShowFailAlert] = useState(false)
  const [batchGrantLicenseMutation] = useBatchGrantLicensesMutation()
  const { data: licensePoolsData } = useLicensePoolsQuery({
    variables: {},
  })

  const { data: customer } = useCustomerQuery({
    skip: !preselectedCustomer,
    variables: { id: preselectedCustomer },
  })
  const { data: merchant } = useMerchantQuery({
    skip: !preselectedMerchant,
    variables: { id: preselectedMerchant },
  })

  const history = useHistory()

  const [formValues, setFormValues] = useState<GrantLicenseFormValues>({
    merchant: preselectedMerchant,
    customer: preselectedCustomer,
    poolStrings: [],
  })

  const licensePoolStrings = useMemo(() => licensePoolEntriesToStrings(licensePoolsData?.licensePools.entries), [
    licensePoolsData?.licensePools.entries,
  ])

  const [
    batchGrantLicenseLabel,
    licensePoolsLabel,
    grantLabel,
    merchantsLabel,
    maximumActivationsLabel,
    noteLabel,
    noneLabel,
    creationFailedLabel,
    nLicensesLabel,
    customersLabel,
    orderNumberLabel,
  ] = useMemo(() => {
    return [
      t('licenses.batchGrantLicense'),
      t('objects.licensePools') + '*',
      t('actions.grant'),
      t('objects.merchants'),
      t('licenses.maximumActivations') + '*',
      t('objects.note'),
      t('customers.none'),
      t('licenses.creationFailed'),
      t('licenses.nLicenses'),
      t('objects.customers'),
      t('objects.orderNumber'),
    ]
  }, [t])

  const grantLicenseAction = useCallback(() => {
    if (formValues.poolStrings.length < 1 || !formValues.maxActivations) {
      setShowFailAlert(true)
      return
    }

    const pools = formValues.poolStrings.map((s) =>
      licensePoolsData?.licensePools.entries.find((e) => licensePoolEntryToString(e) === s),
    )

    const invalidPoolsExist = !!pools.find(
      (p) =>
        p?.n_available !== undefined && p.n_available !== null && p.n_available < parseInt(formValues.nLicenses || '0'),
    )
    if (invalidPoolsExist) {
      setShowFailAlert(true)
      return
    }
    const poolStrings = pools.map((p) => p?.id).filter((p) => p) as string[]

    const batchGrantLicense: BatchGrantLicense = {
      merchant: preselectedMerchant || null,
      customer: preselectedCustomer || null,
      note: formValues?.note || '',
      maximumActivations: parseInt(formValues.maxActivations),
      license_pools: poolStrings,
      nLicenses: parseInt(formValues.nLicenses || '0'),
      orderNumber: formValues?.orderNumber || '',
    }

    batchGrantLicenseMutation({
      variables: {
        batchGrantLicense,
      },
    }).then(() => {
      history.push(ROUTES.licenses)
    })
  }, [
    history,
    batchGrantLicenseMutation,
    noneLabel,
    formValues.merchant,
    formValues.customer,
    formValues.maxActivations,
    formValues.nLicenses,
    formValues.note,
    formValues.orderNumber,
    formValues.poolStrings,
    licensePoolsData?.licensePools.entries,
  ])

  return (
    <>
      <ResultAlert
        alertText={creationFailedLabel}
        showAlert={showFailAlert}
        modifyShowAlert={setShowFailAlert}
        severity="warning"
      />
      <FixedActionBar
        labels={[grantLabel]}
        actions={[grantLicenseAction]}
        icons={[Forward]}
        buttonProps={[{ solid: true }]}
      />
      <PageHeading title={batchGrantLicenseLabel} />
      <Grid container spacing={3} style={{ paddingTop: 20, paddingBottom: 50 }}>
        <Grid item xs={12}>
          <Dropdown
            label={licensePoolsLabel}
            values={licensePoolStrings}
            valueSelected={(s) =>
              setFormValues((fv) => {
                return { ...fv, poolStrings: s }
              })
            }
            multiple={true}
          />
        </Grid>
        {merchant && (
          <Grid item xs={12}>
            <Typography>{convertData([merchant?.merchant])[0].name}</Typography>
          </Grid>
        )}
        {customer && (
          <Grid item xs={12}>
            <Typography>{convertData([customer?.customer])[0].name}</Typography>
          </Grid>
        )}
        <Text
          label={maximumActivationsLabel}
          columns={12}
          onlyNumbers
          value={formValues.maxActivations || ''}
          changeHandler={(maxActivations) =>
            setFormValues((formValues) => {
              return { ...formValues, maxActivations }
            })
          }
        />
        <Text
          label={nLicensesLabel}
          columns={12}
          onlyNumbers
          value={formValues.nLicenses || ''}
          changeHandler={(nLicenses) =>
            setFormValues((formValues) => {
              return { ...formValues, nLicenses }
            })
          }
        />
        <Text
          label={noteLabel}
          columns={12}
          value={formValues.note || ''}
          changeHandler={(note) =>
            setFormValues((formValues) => {
              return { ...formValues, note }
            })
          }
        />
        <Text
          label={orderNumberLabel}
          columns={12}
          value={formValues.orderNumber || ''}
          changeHandler={(orderNumber) =>
            setFormValues((formValues) => {
              return { ...formValues, orderNumber }
            })
          }
        />
      </Grid>
    </>
  )
}
