import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react'
import { useDeactivateLicenseByUuidMutation, useEndUserLicenseActivationsQuery } from '../../../../api/models'
import { Container, Grid } from '@material-ui/core'
import { DataTable } from '../../partials/DataTable/DataTable'
import { PageHeading } from '../../partials/PageHeading'
import { useTranslation } from '../../../hooks/useTranslation'
import { DataTableColumn, DataTableRow } from '../../partials/DataTable/DataTableTypes'
import { toLocaleDateTime } from '../../../helpers/dateTime'
import { SingleFileSelectButton } from '../../partials/Inputs/SingleFileSelectButton'
import { CloudUpload } from '@material-ui/icons'
import { ResultAlert } from '../../partials/ResultAlert'

export const LicenseActivations: FC = ({}): ReactElement => {
  const { data, refetch } = useEndUserLicenseActivationsQuery()
  const { t } = useTranslation()
  const [systemInfoFromFile, setSystemInfoFromFile] = useState('')
  const [deactivateLicenseByUuidMutation, { loading }] = useDeactivateLicenseByUuidMutation()
  const [showDeactivateLicenseByUuidAlert, setShowDeactivateLicenseByUuidAlert] = useState(false)

  useEffect(() => {
    console.log({ systemInfoFromFile })
  }, [systemInfoFromFile])

  useEffect(() => {
    if (systemInfoFromFile.length > 1) {
      deactivateLicenseByUuidMutation({ variables: { id: systemInfoFromFile } }).then((result) => {
        console.log({ result }, { variables: { id: systemInfoFromFile } })
        setShowDeactivateLicenseByUuidAlert(true)
        refetch()
      })
    }
  }, [systemInfoFromFile])

  const columns: DataTableColumn[] = useMemo(
    () => [{ label: t('licenses.licenseKey') }, { label: t('objects.licenseTypes') }, { label: t('objects.date') }],
    [t],
  )

  const rows: DataTableRow[] =
    data?.endUserLicenseActivations.map((activation) => {
      return {
        id: activation.id,
        values: [activation.license_key, activation.types.join(', '), `${toLocaleDateTime(activation.date)}`],
      }
    }) || []

  return (
    <Container style={{ padding: 50 }}>
      <ResultAlert
        alertText={t('license.deactivationSuccess')}
        showAlert={showDeactivateLicenseByUuidAlert}
        modifyShowAlert={setShowDeactivateLicenseByUuidAlert}
        severity="success"
      />
      <PageHeading title={t('licenses.myActivations')} />
      <Grid item xs={5} style={{ paddingTop: 20, paddingBottom: 20 }}>
        <SingleFileSelectButton
          acceptedType="text/*"
          label={t('licenses.selectID')}
          changeHandler={(contents) => setSystemInfoFromFile(atob(contents))}
          icon={CloudUpload}
        />
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <DataTable columns={columns} rows={rows} allowedActions={{ sortable: true, licenseGrantable: false }} />
        </Grid>
      </Grid>
    </Container>
  )
}
