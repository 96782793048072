import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type LicenseInstancePage = {
  __typename?: 'LicenseInstancePage';
  instances: Array<LicenseInstance>;
  total: Scalars['Int'];
};

export enum ActionType {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE'
}

export type LicenseMetadata = {
  hostname: Scalars['String'];
  mac?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  licenseNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  cpuId?: Maybe<Scalars['String']>;
  cpuInfo?: Maybe<Scalars['String']>;
  motherboardSerialnumber?: Maybe<Scalars['String']>;
};

export type SimpleLicenseType = {
  __typename?: 'SimpleLicenseType';
  id: Scalars['ID'];
  type_key: Scalars['String'];
  activation_maximum?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type DashboardInfo = {
  __typename?: 'DashboardInfo';
  nLicensePools: Scalars['Int'];
  nLicenses: Scalars['Int'];
  nCustomers: Scalars['Int'];
  nLicenseTypes: Scalars['Int'];
  nMerchants: Scalars['Int'];
  lastLicensePoolCreationDate?: Maybe<Scalars['Int']>;
  lastLicenseCreationDate?: Maybe<Scalars['Int']>;
  lastMerchantCreationDate?: Maybe<Scalars['Int']>;
  lastCustomerName?: Maybe<Scalars['String']>;
  lastLicenseTypeName?: Maybe<Scalars['String']>;
  nUsers: Scalars['Int'];
};

export type Group = {
  __typename?: 'Group';
  name: Scalars['String'];
  id: Scalars['ID'];
};

export type JournalFilter = {
  type?: Maybe<Scalars['String']>;
  date_from?: Maybe<Scalars['Int']>;
  date_until?: Maybe<Scalars['Int']>;
  contact_id?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['String']>;
  merchant_id?: Maybe<Scalars['String']>;
  license_id?: Maybe<Scalars['String']>;
  license_pool_id?: Maybe<Scalars['String']>;
  log_key?: Maybe<Scalars['String']>;
};

export type LicensePoolInput = {
  group_id: Scalars['ID'];
  license_type_id: Scalars['ID'];
  order_number?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  pool_size?: Maybe<Scalars['Int']>;
};

export type ContactFilter = {
  fulltext?: Maybe<Scalars['String']>;
};

export type UserOfGroup = {
  __typename?: 'UserOfGroup';
  email: Scalars['String'];
  id: Scalars['ID'];
  group?: Maybe<Group>;
};

export type CustomerPage = {
  __typename?: 'CustomerPage';
  customers: Array<Customer>;
  total: Scalars['Int'];
};

export type LicensePage = {
  __typename?: 'LicensePage';
  licenses: Array<License>;
  total: Scalars['Int'];
};

export type EndUserLicenseActivation = {
  __typename?: 'EndUserLicenseActivation';
  id: Scalars['ID'];
  license_key: Scalars['String'];
  date: Scalars['Int'];
  types: Array<Scalars['String']>;
};

export type ContactInput = {
  salutation?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  customerid?: Maybe<Scalars['String']>;
};

export type LicensePool = {
  __typename?: 'LicensePool';
  id: Scalars['ID'];
  creator_user_mail: Scalars['String'];
  client_user_mail: Scalars['String'];
  license_type?: Maybe<LicenseType>;
  order_number?: Maybe<Scalars['String']>;
  date_deleted?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  pool_size?: Maybe<Scalars['Int']>;
  group_id: Scalars['ID'];
};

export type Action = {
  __typename?: 'Action';
  id: Scalars['ID'];
  action_user: Scalars['String'];
  license_name?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  merchant_name?: Maybe<Scalars['String']>;
  license_pool_name?: Maybe<Scalars['String']>;
  license_type_name?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  action_type: ActionType;
  date: Scalars['Int'];
  difference: Scalars['String'];
};

export type LicenseActivation = {
  __typename?: 'LicenseActivation';
  id: Scalars['ID'];
  license_key: Scalars['String'];
  activation_key: Scalars['String'];
  contact_data: Contact;
  hostname: Scalars['String'];
  date: Scalars['Int'];
  date_deleted?: Maybe<Scalars['Int']>;
};

export type GrantOrUpdateLicense = {
  license_pools: Array<Scalars['ID']>;
  merchant?: Maybe<Scalars['ID']>;
  customer?: Maybe<Scalars['ID']>;
  licenceKey: Scalars['String'];
  maximumActivations?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  orderNumber: Scalars['String'];
};

export type BatchGrantLicense = {
  license_pools: Array<Scalars['ID']>;
  merchant?: Maybe<Scalars['ID']>;
  customer?: Maybe<Scalars['ID']>;
  maximumActivations?: Maybe<Scalars['Int']>;
  nLicenses: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  orderNumber: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  actions_journal: Array<Action>;
  contact?: Maybe<Contact>;
  contacts: ContactPage;
  customer?: Maybe<Customer>;
  customers: CustomerPage;
  dashboardInfo: DashboardInfo;
  endUserLicenseActivations: Array<EndUserLicenseActivation>;
  generateLicenseKey: Scalars['String'];
  getAllLicenseInstances: LicenseInstancePage;
  getUserInfo: Array<Maybe<UserInfo>>;
  getUserProfile: UserProfile;
  getUserProfiles: Array<Maybe<UserProfileLight>>;
  /** get user profile version information */
  getVersion: UserProfileVersionInformation;
  groups: Array<Group>;
  license: License;
  licenseInstances: LicenseInstancePage;
  licensePool: LicensePool;
  licensePools: LicensePoolPage;
  licenseType?: Maybe<SimpleLicenseType>;
  licenseTypes: LicenseTypePage;
  licenses: LicensePage;
  licensesOfCustomer: LicensePage;
  licensesOfMerchant: LicensePage;
  merchant?: Maybe<Merchant>;
  merchants: MerchantPage;
  userOfGroup?: Maybe<UserOfGroup>;
  userProfileExport: Array<Maybe<FullUserProfile>>;
  users: UserPage;
};


export type QueryActions_JournalArgs = {
  paginationInfo?: Maybe<PaginationParams>;
  filter?: Maybe<JournalFilter>;
};


export type QueryContactArgs = {
  id: Scalars['ID'];
};


export type QueryContactsArgs = {
  paginationInfo?: Maybe<PaginationParams>;
  filter?: Maybe<ContactFilter>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomersArgs = {
  paginationInfo?: Maybe<PaginationParams>;
  filter?: Maybe<ContactFilter>;
};


export type QueryGetAllLicenseInstancesArgs = {
  fulltext?: Maybe<Scalars['String']>;
  paginationInfo?: Maybe<PaginationParams>;
};


export type QueryGetUserProfilesArgs = {
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryLicenseArgs = {
  id: Scalars['ID'];
};


export type QueryLicenseInstancesArgs = {
  licenseID?: Maybe<Scalars['ID']>;
  paginationInfo?: Maybe<PaginationParams>;
};


export type QueryLicensePoolArgs = {
  id: Scalars['ID'];
};


export type QueryLicensePoolsArgs = {
  paginationInfo?: Maybe<PaginationParams>;
  filter?: Maybe<LicensePoolFilter>;
};


export type QueryLicenseTypeArgs = {
  id: Scalars['ID'];
};


export type QueryLicenseTypesArgs = {
  paginationInfo?: Maybe<PaginationParams>;
};


export type QueryLicensesArgs = {
  paginationInfo?: Maybe<PaginationParams>;
  filter?: Maybe<LicenseFilterUpdater>;
};


export type QueryLicensesOfCustomerArgs = {
  customerID: Scalars['ID'];
  paginationInfo?: Maybe<PaginationParams>;
};


export type QueryLicensesOfMerchantArgs = {
  merchantID: Scalars['ID'];
  paginationInfo?: Maybe<PaginationParams>;
};


export type QueryMerchantArgs = {
  id: Scalars['ID'];
};


export type QueryMerchantsArgs = {
  paginationInfo?: Maybe<PaginationParams>;
  filter?: Maybe<ContactFilter>;
};


export type QueryUserOfGroupArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  paginationInfo?: Maybe<PaginationParams>;
  groupID?: Maybe<Scalars['ID']>;
};

export type LicenseInstance = {
  __typename?: 'LicenseInstance';
  id: Scalars['ID'];
  license_instance_key: Scalars['String'];
  user_id: Scalars['ID'];
  date_created?: Maybe<Scalars['Int']>;
  date_deleted?: Maybe<Scalars['Int']>;
  system_identifiers?: Maybe<Scalars['String']>;
  contact: Contact;
  license: License;
};

export type LicensePoolFilter = {
  type_key?: Maybe<Scalars['String']>;
  date_from?: Maybe<Scalars['Int']>;
  date_until?: Maybe<Scalars['Int']>;
};

export type PaginationParamsLicenseInstance = {
  limit?: Maybe<Scalars['Int']>;
  Offset?: Maybe<Scalars['Int']>;
  LicenseID?: Maybe<Scalars['String']>;
  Fulltext?: Maybe<Scalars['String']>;
};

export type MerchantPage = {
  __typename?: 'MerchantPage';
  merchants: Array<Merchant>;
  total: Scalars['Int'];
};

export type ContactPage = {
  __typename?: 'ContactPage';
  contacts: Array<Contact>;
  total: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  roles: Array<Scalars['String']>;
};

export type PaginationParams = {
  limit?: Maybe<Scalars['Int']>;
  Offset?: Maybe<Scalars['Int']>;
};

export type LicenseTypeInput = {
  type_key: Scalars['String'];
  activation_maximum?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type LicensePoolPageEntry = {
  __typename?: 'LicensePoolPageEntry';
  id: Scalars['ID'];
  creator_user_mail: Scalars['String'];
  license_type_key?: Maybe<Scalars['String']>;
  pool_size?: Maybe<Scalars['Int']>;
  n_available?: Maybe<Scalars['Int']>;
  n_in_use: Scalars['Int'];
  date_deleted?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  group_id: Scalars['ID'];
};

export type LicenseFilterUpdater = {
  merchant_id?: Maybe<Scalars['ID']>;
  customer_id?: Maybe<Scalars['ID']>;
  license_key?: Maybe<Scalars['String']>;
  date_from?: Maybe<Scalars['Int']>;
  date_until?: Maybe<Scalars['Int']>;
  fulltext?: Maybe<Scalars['String']>;
};

export type Merchant = {
  __typename?: 'Merchant';
  id: Scalars['ID'];
  contact?: Maybe<Contact>;
  date_deleted?: Maybe<Scalars['Int']>;
  nLicenses: Scalars['Int'];
};

export type LicensePoolPage = {
  __typename?: 'LicensePoolPage';
  entries: Array<LicensePoolPageEntry>;
  total: Scalars['Int'];
};

export type LicenseType = {
  __typename?: 'LicenseType';
  id: Scalars['ID'];
  type_key: Scalars['String'];
  activation_maximum?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  date_deleted?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type License = {
  __typename?: 'License';
  id: Scalars['ID'];
  license_key: Scalars['String'];
  client_user?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  merchant?: Maybe<Merchant>;
  date_deleted?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  activation_maximum?: Maybe<Scalars['Int']>;
  nActivations: Scalars['Int'];
  changed_by: Scalars['String'];
  orderNumber: Scalars['String'];
  pools: Array<LicensePoolPageEntry>;
};

export type LicenseTypePage = {
  __typename?: 'LicenseTypePage';
  licenseTypes: Array<LicenseType>;
  total: Scalars['Int'];
};

export type UserPage = {
  __typename?: 'UserPage';
  users: Array<User>;
  total: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateLicense: Scalars['String'];
  batchGrantLicenses: Scalars['Boolean'];
  changeUserProfileEmail: Scalars['Boolean'];
  createCustomer: Scalars['ID'];
  createLicensePool: Scalars['ID'];
  createLicenseType: Scalars['ID'];
  createMerchant: Scalars['ID'];
  createUserProfile: Scalars['Boolean'];
  createUserProfileEmail: Scalars['Boolean'];
  deactivateLicense: Scalars['Boolean'];
  deactivateLicenseByInstanceID: Scalars['Boolean'];
  deactivateLicenseByUuid: Scalars['Boolean'];
  deleteCustomer: Scalars['Boolean'];
  deleteLicense: Scalars['Boolean'];
  deleteLicenseInstance: Scalars['Boolean'];
  deleteLicensePool: Scalars['Boolean'];
  deleteLicenseType: Scalars['Boolean'];
  deleteMerchant: Scalars['Boolean'];
  deleteUserProfile: Scalars['Boolean'];
  deleteUserProfileEmailFull: Scalars['Boolean'];
  deleteUserProfileFull: Scalars['Boolean'];
  editLicense: Scalars['Boolean'];
  grantLicense: Scalars['ID'];
  updateCustomer: Scalars['Boolean'];
  updateLicensePool: Scalars['Boolean'];
  updateLicenseType: Scalars['Boolean'];
  updateMerchant: Scalars['Boolean'];
  updateUserProfileData: Scalars['Boolean'];
  updateUserProfileEmailOptin: Scalars['Boolean'];
  updateUserProfileImage: Scalars['Boolean'];
  updateUserProfileOptin: Scalars['Boolean'];
  userProfileImport: Scalars['Boolean'];
};


export type MutationActivateLicenseArgs = {
  license_key: Scalars['String'];
  metadata: LicenseMetadata;
};


export type MutationBatchGrantLicensesArgs = {
  params: BatchGrantLicense;
};


export type MutationCreateCustomerArgs = {
  contact: ContactInput;
};


export type MutationCreateLicensePoolArgs = {
  licensePool: LicensePoolInput;
};


export type MutationCreateLicenseTypeArgs = {
  licenseType: LicenseTypeInput;
};


export type MutationCreateMerchantArgs = {
  contact: ContactInput;
};


export type MutationCreateUserProfileArgs = {
  userProfile: UserProfileInput;
};


export type MutationCreateUserProfileEmailArgs = {
  userProfile: UserProfileEmailInput;
};


export type MutationDeactivateLicenseArgs = {
  metadata: LicenseMetadata;
};


export type MutationDeactivateLicenseByInstanceIdArgs = {
  id: Scalars['ID'];
};


export type MutationDeactivateLicenseByUuidArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLicenseArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLicenseInstanceArgs = {
  licenseInstanceID: Scalars['ID'];
};


export type MutationDeleteLicensePoolArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLicenseTypeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMerchantArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserProfileEmailFullArgs = {
  email: Scalars['String'];
};


export type MutationEditLicenseArgs = {
  licenseID: Scalars['ID'];
  params: GrantOrUpdateLicense;
};


export type MutationGrantLicenseArgs = {
  params: GrantOrUpdateLicense;
};


export type MutationUpdateCustomerArgs = {
  id: Scalars['ID'];
  contact: ContactInput;
};


export type MutationUpdateLicensePoolArgs = {
  id: Scalars['ID'];
  licensePool: LicensePoolInput;
};


export type MutationUpdateLicenseTypeArgs = {
  id: Scalars['ID'];
  licenseType: LicenseTypeInput;
};


export type MutationUpdateMerchantArgs = {
  id: Scalars['ID'];
  contact: ContactInput;
};


export type MutationUpdateUserProfileDataArgs = {
  userProfileData: UserProfileDataInput;
};


export type MutationUpdateUserProfileEmailOptinArgs = {
  userProfileOptin: UserProfileEmailOptinInput;
};


export type MutationUpdateUserProfileImageArgs = {
  userProfileImage: UserProfileIdImageInput;
};


export type MutationUpdateUserProfileOptinArgs = {
  userProfileOptin: UserProfileOptinInput;
};


export type MutationUserProfileImportArgs = {
  userProfiles: Array<Maybe<FullUserProfileInput>>;
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  salutation?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  customerid?: Maybe<Scalars['String']>;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  contact?: Maybe<Contact>;
  date_deleted?: Maybe<Scalars['Int']>;
  nLicenses: Scalars['Int'];
};

export type UserProfileIdImage = {
  __typename?: 'UserProfileIdImage';
  Name: Scalars['String'];
  Type: Scalars['String'];
  Content: Scalars['String'];
};

export type UserProfileData = {
  __typename?: 'UserProfileData';
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Email: Scalars['String'];
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type UserProfileOptinInput = {
  Newsletter: Scalars['Boolean'];
};

export type UserProfileIdImageInput = {
  Name: Scalars['String'];
  Type: Scalars['String'];
  Content: Scalars['String'];
};

export type UserProfileEmail = {
  __typename?: 'UserProfileEmail';
  Email: Scalars['String'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type FullUserProfileDataInput = {
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Email: Scalars['String'];
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type UserProfileEmailInput = {
  Email: Scalars['String'];
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  Date: Scalars['String'];
  Toolname?: Maybe<Scalars['String']>;
};

export type UserProfileLight = {
  __typename?: 'UserProfileLight';
  userId: Scalars['String'];
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Email: Scalars['String'];
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
};

export type UserProfileOptIn = {
  __typename?: 'UserProfileOptIn';
  Newsletter: Scalars['Boolean'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type UserProfileEmailOptinInput = {
  Email: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Date: Scalars['String'];
  Toolname?: Maybe<Scalars['String']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Email: Scalars['String'];
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
  Image?: Maybe<UserProfileIdImage>;
  CreationDate: Scalars['String'];
};

export type UserProfileInput = {
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
  CreationDate: Scalars['String'];
};

export type UserProfileDataInput = {
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
};

export type UserProfileImage = {
  __typename?: 'UserProfileImage';
  Name: Scalars['String'];
  Type: Scalars['String'];
  Content: Scalars['String'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type FullUserProfileInput = {
  userId: Scalars['String'];
  CreationDate: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
  Data: Array<Maybe<FullUserProfileDataInput>>;
  Image: Array<Maybe<FullUserProfileImageInput>>;
  Email: Array<Maybe<FullUserProfileEmailInput>>;
  OptIn: Array<Maybe<FullUserProfileOptInInput>>;
};

export type FullUserProfileOptInInput = {
  Newsletter: Scalars['Boolean'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type FullUserProfile = {
  __typename?: 'FullUserProfile';
  userId: Scalars['String'];
  CreationDate: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
  Data: Array<Maybe<UserProfileData>>;
  Image: Array<Maybe<UserProfileImage>>;
  Email: Array<Maybe<UserProfileEmail>>;
  OptIn: Array<Maybe<UserProfileOptIn>>;
};

export type FullUserProfileImageInput = {
  Name: Scalars['String'];
  Type: Scalars['String'];
  Content: Scalars['String'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type FullUserProfileEmailInput = {
  Email: Scalars['String'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type UserProfileVersionInformation = {
  __typename?: 'UserProfileVersionInformation';
  /** version of user profile service */
  version: Scalars['String'];
  /** timestamp of the iam build */
  buildTimestamp: Scalars['String'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  company: Scalars['String'];
};

export type CreateCustomerMutationVariables = Exact<{
  contact: ContactInput;
}>;


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCustomer'>
);

export type CustomersQueryVariables = Exact<{
  paginationParams?: Maybe<PaginationParams>;
  filter?: Maybe<ContactFilter>;
}>;


export type CustomersQuery = (
  { __typename?: 'Query' }
  & { customers: (
    { __typename?: 'CustomerPage' }
    & Pick<CustomerPage, 'total'>
    & { customers: Array<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'nLicenses'>
      & { contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'city' | 'company' | 'country_code' | 'email' | 'fax' | 'first_name' | 'last_name' | 'middle_name' | 'mobile' | 'note' | 'phone' | 'position' | 'post_code' | 'salutation' | 'state' | 'street' | 'website' | 'customerid'>
      )> }
    )> }
  ) }
);

export type CustomersBasicInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomersBasicInfoQuery = (
  { __typename?: 'Query' }
  & { customers: (
    { __typename?: 'CustomerPage' }
    & Pick<CustomerPage, 'total'>
    & { customers: Array<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id'>
      & { contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'first_name' | 'last_name' | 'company' | 'customerid'>
      )> }
    )> }
  ) }
);

export type UpdateCustomerMutationVariables = Exact<{
  id: Scalars['ID'];
  contact: ContactInput;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCustomer'>
);

export type CustomerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CustomerQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'nLicenses' | 'id'>
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'city' | 'company' | 'country_code' | 'email' | 'fax' | 'first_name' | 'last_name' | 'middle_name' | 'mobile' | 'note' | 'phone' | 'position' | 'post_code' | 'salutation' | 'state' | 'street' | 'website' | 'customerid'>
    )> }
  )> }
);

export type CustomerBasicInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CustomerBasicInfoQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'first_name' | 'last_name' | 'company' | 'customerid'>
    )> }
  )> }
);

export type DeleteCustomerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCustomer'>
);

export type DashboardInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardInfoQuery = (
  { __typename?: 'Query' }
  & { dashboardInfo: (
    { __typename?: 'DashboardInfo' }
    & Pick<DashboardInfo, 'lastCustomerName' | 'lastLicenseCreationDate' | 'lastLicensePoolCreationDate' | 'lastLicenseTypeName' | 'lastMerchantCreationDate' | 'nCustomers' | 'nLicensePools' | 'nLicenseTypes' | 'nLicenses' | 'nMerchants' | 'nUsers'>
  ) }
);

export type GroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupsQuery = (
  { __typename?: 'Query' }
  & { groups: Array<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type Actions_JournalQueryVariables = Exact<{
  paginationInfo?: Maybe<PaginationParams>;
  filter?: Maybe<JournalFilter>;
}>;


export type Actions_JournalQuery = (
  { __typename?: 'Query' }
  & { actions_journal: Array<(
    { __typename?: 'Action' }
    & Pick<Action, 'action_type' | 'action_user' | 'id' | 'license_name' | 'contact_name' | 'merchant_name' | 'license_pool_name' | 'customer_name' | 'date' | 'difference' | 'license_type_name'>
  )> }
);

export type LicensePoolsQueryVariables = Exact<{
  paginationParams?: Maybe<PaginationParams>;
  filter?: Maybe<LicensePoolFilter>;
}>;


export type LicensePoolsQuery = (
  { __typename?: 'Query' }
  & { licensePools: (
    { __typename?: 'LicensePoolPage' }
    & Pick<LicensePoolPage, 'total'>
    & { entries: Array<(
      { __typename?: 'LicensePoolPageEntry' }
      & Pick<LicensePoolPageEntry, 'creator_user_mail' | 'date_created' | 'date_deleted' | 'id' | 'license_type_key' | 'n_available' | 'group_id' | 'n_in_use' | 'note' | 'pool_size'>
    )> }
  ) }
);

export type LicensePoolQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LicensePoolQuery = (
  { __typename?: 'Query' }
  & { licensePool: (
    { __typename?: 'LicensePool' }
    & Pick<LicensePool, 'creator_user_mail' | 'date_created' | 'date_deleted' | 'client_user_mail' | 'id' | 'group_id' | 'order_number' | 'note' | 'pool_size'>
    & { license_type?: Maybe<(
      { __typename?: 'LicenseType' }
      & Pick<LicenseType, 'type_key'>
    )> }
  ) }
);

export type CreateLicensePoolMutationVariables = Exact<{
  licensePool: LicensePoolInput;
}>;


export type CreateLicensePoolMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createLicensePool'>
);

export type UpdateLicensePoolMutationVariables = Exact<{
  id: Scalars['ID'];
  licensePool: LicensePoolInput;
}>;


export type UpdateLicensePoolMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateLicensePool'>
);

export type DeleteLicensePoolMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLicensePoolMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLicensePool'>
);

export type LicenseTypesQueryVariables = Exact<{
  paginationParams?: Maybe<PaginationParams>;
}>;


export type LicenseTypesQuery = (
  { __typename?: 'Query' }
  & { licenseTypes: (
    { __typename?: 'LicenseTypePage' }
    & Pick<LicenseTypePage, 'total'>
    & { licenseTypes: Array<(
      { __typename?: 'LicenseType' }
      & Pick<LicenseType, 'activation_maximum' | 'date_created' | 'date_deleted' | 'description' | 'id' | 'type_key'>
    )> }
  ) }
);

export type LicenseTypeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LicenseTypeQuery = (
  { __typename?: 'Query' }
  & { licenseType?: Maybe<(
    { __typename?: 'SimpleLicenseType' }
    & Pick<SimpleLicenseType, 'activation_maximum' | 'description' | 'id' | 'type_key'>
  )> }
);

export type CreateLicenseTypeMutationVariables = Exact<{
  licenseType: LicenseTypeInput;
}>;


export type CreateLicenseTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createLicenseType'>
);

export type UpdateLicenseTypeMutationVariables = Exact<{
  id: Scalars['ID'];
  licenseType: LicenseTypeInput;
}>;


export type UpdateLicenseTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateLicenseType'>
);

export type DeleteLicenseTypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLicenseTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLicenseType'>
);

export type GenerateLicenseKeyQueryVariables = Exact<{ [key: string]: never; }>;


export type GenerateLicenseKeyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'generateLicenseKey'>
);

export type GrantLicenseMutationVariables = Exact<{
  grantLicense: GrantOrUpdateLicense;
}>;


export type GrantLicenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'grantLicense'>
);

export type BatchGrantLicensesMutationVariables = Exact<{
  batchGrantLicense: BatchGrantLicense;
}>;


export type BatchGrantLicensesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'batchGrantLicenses'>
);

export type ActivateLicenseMutationVariables = Exact<{
  licenseKey: Scalars['String'];
  metadata: LicenseMetadata;
}>;


export type ActivateLicenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateLicense'>
);

export type DeactivateLicenseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeactivateLicenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deactivateLicenseByInstanceID'>
);

export type DeactivateLicenseByUuidMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeactivateLicenseByUuidMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deactivateLicenseByUuid'>
);

export type EditLicenseMutationVariables = Exact<{
  id: Scalars['ID'];
  grantLicense: GrantOrUpdateLicense;
}>;


export type EditLicenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editLicense'>
);

export type DeleteLicenseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLicenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLicense'>
);

export type LicenseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LicenseQuery = (
  { __typename?: 'Query' }
  & { license: (
    { __typename?: 'License' }
    & Pick<License, 'activation_maximum' | 'changed_by' | 'client_user' | 'date_created' | 'date_deleted' | 'date_updated' | 'id' | 'license_key' | 'nActivations' | 'orderNumber' | 'note'>
    & { customer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id'>
      & { contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'first_name' | 'last_name' | 'company'>
      )> }
    )>, merchant?: Maybe<(
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id'>
      & { contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'first_name' | 'last_name' | 'company'>
      )> }
    )>, pools: Array<(
      { __typename?: 'LicensePoolPageEntry' }
      & Pick<LicensePoolPageEntry, 'creator_user_mail' | 'date_created' | 'date_deleted' | 'id' | 'license_type_key' | 'n_available' | 'group_id' | 'n_in_use' | 'note' | 'pool_size'>
    )> }
  ) }
);

export type LicensesQueryVariables = Exact<{
  paginationInfo?: Maybe<PaginationParams>;
  filter?: Maybe<LicenseFilterUpdater>;
}>;


export type LicensesQuery = (
  { __typename?: 'Query' }
  & { licenses: (
    { __typename?: 'LicensePage' }
    & Pick<LicensePage, 'total'>
    & { licenses: Array<(
      { __typename?: 'License' }
      & Pick<License, 'activation_maximum' | 'changed_by' | 'client_user' | 'date_created' | 'date_deleted' | 'date_updated' | 'id' | 'license_key' | 'nActivations' | 'orderNumber' | 'note'>
      & { customer?: Maybe<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'id'>
        & { contact?: Maybe<(
          { __typename?: 'Contact' }
          & Pick<Contact, 'first_name' | 'last_name' | 'customerid' | 'company'>
        )> }
      )>, merchant?: Maybe<(
        { __typename?: 'Merchant' }
        & Pick<Merchant, 'id'>
        & { contact?: Maybe<(
          { __typename?: 'Contact' }
          & Pick<Contact, 'first_name' | 'last_name' | 'company'>
        )> }
      )>, pools: Array<(
        { __typename?: 'LicensePoolPageEntry' }
        & Pick<LicensePoolPageEntry, 'id' | 'pool_size' | 'n_in_use' | 'license_type_key'>
      )> }
    )> }
  ) }
);

export type EndUserLicenseActivationsQueryVariables = Exact<{ [key: string]: never; }>;


export type EndUserLicenseActivationsQuery = (
  { __typename?: 'Query' }
  & { endUserLicenseActivations: Array<(
    { __typename?: 'EndUserLicenseActivation' }
    & Pick<EndUserLicenseActivation, 'date' | 'id' | 'license_key' | 'types'>
  )> }
);

export type LicenseInstancesQueryVariables = Exact<{
  paginationInfo?: Maybe<PaginationParams>;
  licenseID?: Maybe<Scalars['ID']>;
}>;


export type LicenseInstancesQuery = (
  { __typename?: 'Query' }
  & { licenseInstances: (
    { __typename?: 'LicenseInstancePage' }
    & Pick<LicenseInstancePage, 'total'>
    & { instances: Array<(
      { __typename?: 'LicenseInstance' }
      & Pick<LicenseInstance, 'date_created' | 'date_deleted' | 'system_identifiers' | 'id' | 'license_instance_key' | 'user_id'>
      & { contact: (
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'first_name' | 'last_name' | 'company' | 'street' | 'post_code' | 'city' | 'country_code' | 'note' | 'customerid' | 'email'>
      ), license: (
        { __typename?: 'License' }
        & Pick<License, 'activation_maximum' | 'id' | 'license_key' | 'nActivations' | 'orderNumber' | 'note'>
        & { pools: Array<(
          { __typename?: 'LicensePoolPageEntry' }
          & Pick<LicensePoolPageEntry, 'id' | 'license_type_key' | 'pool_size' | 'date_deleted' | 'date_created' | 'note'>
        )>, customer?: Maybe<(
          { __typename?: 'Customer' }
          & Pick<Customer, 'id'>
          & { contact?: Maybe<(
            { __typename?: 'Contact' }
            & Pick<Contact, 'id' | 'first_name' | 'last_name' | 'company' | 'street' | 'post_code' | 'city' | 'country_code' | 'note' | 'customerid' | 'email'>
          )> }
        )>, merchant?: Maybe<(
          { __typename?: 'Merchant' }
          & Pick<Merchant, 'id'>
          & { contact?: Maybe<(
            { __typename?: 'Contact' }
            & Pick<Contact, 'id' | 'first_name' | 'last_name' | 'company' | 'street' | 'post_code' | 'city' | 'country_code' | 'note' | 'customerid' | 'email'>
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type GetAllLicenseInstancesQueryVariables = Exact<{
  paginationInfo?: Maybe<PaginationParams>;
  fulltext?: Maybe<Scalars['String']>;
}>;


export type GetAllLicenseInstancesQuery = (
  { __typename?: 'Query' }
  & { getAllLicenseInstances: (
    { __typename?: 'LicenseInstancePage' }
    & Pick<LicenseInstancePage, 'total'>
    & { instances: Array<(
      { __typename?: 'LicenseInstance' }
      & Pick<LicenseInstance, 'date_created' | 'date_deleted' | 'system_identifiers' | 'id' | 'license_instance_key' | 'user_id'>
      & { contact: (
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'first_name' | 'last_name' | 'company' | 'street' | 'post_code' | 'city' | 'country_code' | 'note' | 'customerid' | 'email'>
      ), license: (
        { __typename?: 'License' }
        & Pick<License, 'activation_maximum' | 'id' | 'license_key' | 'nActivations' | 'orderNumber' | 'note'>
        & { pools: Array<(
          { __typename?: 'LicensePoolPageEntry' }
          & Pick<LicensePoolPageEntry, 'id' | 'license_type_key' | 'pool_size' | 'date_deleted' | 'date_created' | 'note'>
        )>, customer?: Maybe<(
          { __typename?: 'Customer' }
          & Pick<Customer, 'id'>
          & { contact?: Maybe<(
            { __typename?: 'Contact' }
            & Pick<Contact, 'id' | 'first_name' | 'last_name' | 'company' | 'street' | 'post_code' | 'city' | 'country_code' | 'note' | 'customerid' | 'email'>
          )> }
        )>, merchant?: Maybe<(
          { __typename?: 'Merchant' }
          & Pick<Merchant, 'id'>
          & { contact?: Maybe<(
            { __typename?: 'Contact' }
            & Pick<Contact, 'id' | 'first_name' | 'last_name' | 'company' | 'street' | 'post_code' | 'city' | 'country_code' | 'note' | 'customerid' | 'email'>
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type CreateMerchantMutationVariables = Exact<{
  contact: ContactInput;
}>;


export type CreateMerchantMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMerchant'>
);

export type MerchantsQueryVariables = Exact<{
  paginationParams?: Maybe<PaginationParams>;
  filter?: Maybe<ContactFilter>;
}>;


export type MerchantsQuery = (
  { __typename?: 'Query' }
  & { merchants: (
    { __typename?: 'MerchantPage' }
    & Pick<MerchantPage, 'total'>
    & { merchants: Array<(
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id' | 'nLicenses'>
      & { contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'city' | 'company' | 'country_code' | 'email' | 'fax' | 'first_name' | 'last_name' | 'middle_name' | 'mobile' | 'note' | 'phone' | 'position' | 'post_code' | 'salutation' | 'state' | 'street' | 'website' | 'customerid'>
      )> }
    )> }
  ) }
);

export type MerchantsBasicInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type MerchantsBasicInfoQuery = (
  { __typename?: 'Query' }
  & { merchants: (
    { __typename?: 'MerchantPage' }
    & Pick<MerchantPage, 'total'>
    & { merchants: Array<(
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id'>
      & { contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'first_name' | 'last_name' | 'company' | 'customerid'>
      )> }
    )> }
  ) }
);

export type UpdateMerchantMutationVariables = Exact<{
  id: Scalars['ID'];
  contact: ContactInput;
}>;


export type UpdateMerchantMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMerchant'>
);

export type MerchantQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MerchantQuery = (
  { __typename?: 'Query' }
  & { merchant?: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'nLicenses' | 'id'>
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'city' | 'company' | 'country_code' | 'email' | 'fax' | 'first_name' | 'last_name' | 'middle_name' | 'mobile' | 'note' | 'phone' | 'position' | 'post_code' | 'salutation' | 'state' | 'street' | 'website' | 'customerid'>
    )> }
  )> }
);

export type DeleteMerchantMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMerchantMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMerchant'>
);

export type GetUserProfilesQueryVariables = Exact<{
  userIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GetUserProfilesQuery = (
  { __typename?: 'Query' }
  & { getUserProfiles: Array<Maybe<(
    { __typename?: 'UserProfileLight' }
    & Pick<UserProfileLight, 'userId' | 'Title' | 'FirstName' | 'LastName' | 'CompanyName' | 'Phone' | 'Mobile' | 'Fax' | 'Email' | 'Street' | 'Zip' | 'City' | 'Country' | 'Newsletter' | 'Department' | 'Relationship' | 'TermsOfUse'>
  )>> }
);

export type UsersQueryVariables = Exact<{
  paginationParams?: Maybe<PaginationParams>;
  groupID?: Maybe<Scalars['ID']>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UserPage' }
    & Pick<UserPage, 'total'>
    & { users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'roles'>
    )> }
  ) }
);

export type UserOfGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserOfGroupQuery = (
  { __typename?: 'Query' }
  & { userOfGroup?: Maybe<(
    { __typename?: 'UserOfGroup' }
    & Pick<UserOfGroup, 'email' | 'id'>
    & { group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )> }
  )> }
);


export const CreateCustomerDocument = gql`
    mutation createCustomer($contact: ContactInput!) {
  createCustomer(contact: $contact)
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const CustomersDocument = gql`
    query customers($paginationParams: PaginationParams, $filter: ContactFilter) {
  customers(paginationInfo: $paginationParams, filter: $filter) {
    total
    customers {
      id
      nLicenses
      contact {
        city
        company
        country_code
        email
        fax
        first_name
        last_name
        middle_name
        mobile
        note
        phone
        position
        post_code
        salutation
        state
        state
        street
        website
        customerid
      }
    }
  }
}
    `;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      paginationParams: // value for 'paginationParams'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
      }
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
        }
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const CustomersBasicInfoDocument = gql`
    query customersBasicInfo {
  customers {
    total
    customers {
      id
      contact {
        first_name
        last_name
        company
        customerid
      }
    }
  }
}
    `;

/**
 * __useCustomersBasicInfoQuery__
 *
 * To run a query within a React component, call `useCustomersBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersBasicInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomersBasicInfoQuery(baseOptions?: Apollo.QueryHookOptions<CustomersBasicInfoQuery, CustomersBasicInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersBasicInfoQuery, CustomersBasicInfoQueryVariables>(CustomersBasicInfoDocument, options);
      }
export function useCustomersBasicInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersBasicInfoQuery, CustomersBasicInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersBasicInfoQuery, CustomersBasicInfoQueryVariables>(CustomersBasicInfoDocument, options);
        }
export type CustomersBasicInfoQueryHookResult = ReturnType<typeof useCustomersBasicInfoQuery>;
export type CustomersBasicInfoLazyQueryHookResult = ReturnType<typeof useCustomersBasicInfoLazyQuery>;
export type CustomersBasicInfoQueryResult = Apollo.QueryResult<CustomersBasicInfoQuery, CustomersBasicInfoQueryVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($id: ID!, $contact: ContactInput!) {
  updateCustomer(id: $id, contact: $contact)
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const CustomerDocument = gql`
    query customer($id: ID!) {
  customer(id: $id) {
    nLicenses
    id
    contact {
      city
      company
      country_code
      email
      fax
      first_name
      last_name
      middle_name
      mobile
      note
      phone
      position
      post_code
      salutation
      state
      state
      street
      website
      customerid
    }
  }
}
    `;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
      }
export function useCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const CustomerBasicInfoDocument = gql`
    query customerBasicInfo($id: ID!) {
  customer(id: $id) {
    id
    contact {
      first_name
      last_name
      company
      customerid
    }
  }
}
    `;

/**
 * __useCustomerBasicInfoQuery__
 *
 * To run a query within a React component, call `useCustomerBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerBasicInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerBasicInfoQuery(baseOptions: Apollo.QueryHookOptions<CustomerBasicInfoQuery, CustomerBasicInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerBasicInfoQuery, CustomerBasicInfoQueryVariables>(CustomerBasicInfoDocument, options);
      }
export function useCustomerBasicInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerBasicInfoQuery, CustomerBasicInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerBasicInfoQuery, CustomerBasicInfoQueryVariables>(CustomerBasicInfoDocument, options);
        }
export type CustomerBasicInfoQueryHookResult = ReturnType<typeof useCustomerBasicInfoQuery>;
export type CustomerBasicInfoLazyQueryHookResult = ReturnType<typeof useCustomerBasicInfoLazyQuery>;
export type CustomerBasicInfoQueryResult = Apollo.QueryResult<CustomerBasicInfoQuery, CustomerBasicInfoQueryVariables>;
export const DeleteCustomerDocument = gql`
    mutation deleteCustomer($id: ID!) {
  deleteCustomer(id: $id)
}
    `;
export type DeleteCustomerMutationFn = Apollo.MutationFunction<DeleteCustomerMutation, DeleteCustomerMutationVariables>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DeleteCustomerDocument, options);
      }
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export const DashboardInfoDocument = gql`
    query dashboardInfo {
  dashboardInfo {
    lastCustomerName
    lastLicenseCreationDate
    lastLicensePoolCreationDate
    lastLicenseTypeName
    lastMerchantCreationDate
    nCustomers
    nLicensePools
    nLicenseTypes
    nLicenses
    nMerchants
    nUsers
  }
}
    `;

/**
 * __useDashboardInfoQuery__
 *
 * To run a query within a React component, call `useDashboardInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardInfoQuery(baseOptions?: Apollo.QueryHookOptions<DashboardInfoQuery, DashboardInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardInfoQuery, DashboardInfoQueryVariables>(DashboardInfoDocument, options);
      }
export function useDashboardInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardInfoQuery, DashboardInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardInfoQuery, DashboardInfoQueryVariables>(DashboardInfoDocument, options);
        }
export type DashboardInfoQueryHookResult = ReturnType<typeof useDashboardInfoQuery>;
export type DashboardInfoLazyQueryHookResult = ReturnType<typeof useDashboardInfoLazyQuery>;
export type DashboardInfoQueryResult = Apollo.QueryResult<DashboardInfoQuery, DashboardInfoQueryVariables>;
export const GroupsDocument = gql`
    query groups {
  groups {
    id
    name
  }
}
    `;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
      }
export function useGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
        }
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = Apollo.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const Actions_JournalDocument = gql`
    query actions_journal($paginationInfo: PaginationParams, $filter: JournalFilter) {
  actions_journal(paginationInfo: $paginationInfo, filter: $filter) {
    action_type
    action_user
    id
    license_name
    contact_name
    merchant_name
    license_pool_name
    customer_name
    date
    difference
    license_type_name
  }
}
    `;

/**
 * __useActions_JournalQuery__
 *
 * To run a query within a React component, call `useActions_JournalQuery` and pass it any options that fit your needs.
 * When your component renders, `useActions_JournalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActions_JournalQuery({
 *   variables: {
 *      paginationInfo: // value for 'paginationInfo'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useActions_JournalQuery(baseOptions?: Apollo.QueryHookOptions<Actions_JournalQuery, Actions_JournalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Actions_JournalQuery, Actions_JournalQueryVariables>(Actions_JournalDocument, options);
      }
export function useActions_JournalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Actions_JournalQuery, Actions_JournalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Actions_JournalQuery, Actions_JournalQueryVariables>(Actions_JournalDocument, options);
        }
export type Actions_JournalQueryHookResult = ReturnType<typeof useActions_JournalQuery>;
export type Actions_JournalLazyQueryHookResult = ReturnType<typeof useActions_JournalLazyQuery>;
export type Actions_JournalQueryResult = Apollo.QueryResult<Actions_JournalQuery, Actions_JournalQueryVariables>;
export const LicensePoolsDocument = gql`
    query licensePools($paginationParams: PaginationParams, $filter: LicensePoolFilter) {
  licensePools(paginationInfo: $paginationParams, filter: $filter) {
    total
    entries {
      creator_user_mail
      date_created
      date_deleted
      id
      license_type_key
      n_available
      group_id
      n_in_use
      note
      pool_size
    }
  }
}
    `;

/**
 * __useLicensePoolsQuery__
 *
 * To run a query within a React component, call `useLicensePoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicensePoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicensePoolsQuery({
 *   variables: {
 *      paginationParams: // value for 'paginationParams'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLicensePoolsQuery(baseOptions?: Apollo.QueryHookOptions<LicensePoolsQuery, LicensePoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicensePoolsQuery, LicensePoolsQueryVariables>(LicensePoolsDocument, options);
      }
export function useLicensePoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicensePoolsQuery, LicensePoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicensePoolsQuery, LicensePoolsQueryVariables>(LicensePoolsDocument, options);
        }
export type LicensePoolsQueryHookResult = ReturnType<typeof useLicensePoolsQuery>;
export type LicensePoolsLazyQueryHookResult = ReturnType<typeof useLicensePoolsLazyQuery>;
export type LicensePoolsQueryResult = Apollo.QueryResult<LicensePoolsQuery, LicensePoolsQueryVariables>;
export const LicensePoolDocument = gql`
    query licensePool($id: ID!) {
  licensePool(id: $id) {
    creator_user_mail
    date_created
    date_deleted
    client_user_mail
    id
    group_id
    order_number
    license_type {
      type_key
    }
    note
    pool_size
  }
}
    `;

/**
 * __useLicensePoolQuery__
 *
 * To run a query within a React component, call `useLicensePoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicensePoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicensePoolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLicensePoolQuery(baseOptions: Apollo.QueryHookOptions<LicensePoolQuery, LicensePoolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicensePoolQuery, LicensePoolQueryVariables>(LicensePoolDocument, options);
      }
export function useLicensePoolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicensePoolQuery, LicensePoolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicensePoolQuery, LicensePoolQueryVariables>(LicensePoolDocument, options);
        }
export type LicensePoolQueryHookResult = ReturnType<typeof useLicensePoolQuery>;
export type LicensePoolLazyQueryHookResult = ReturnType<typeof useLicensePoolLazyQuery>;
export type LicensePoolQueryResult = Apollo.QueryResult<LicensePoolQuery, LicensePoolQueryVariables>;
export const CreateLicensePoolDocument = gql`
    mutation createLicensePool($licensePool: LicensePoolInput!) {
  createLicensePool(licensePool: $licensePool)
}
    `;
export type CreateLicensePoolMutationFn = Apollo.MutationFunction<CreateLicensePoolMutation, CreateLicensePoolMutationVariables>;

/**
 * __useCreateLicensePoolMutation__
 *
 * To run a mutation, you first call `useCreateLicensePoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicensePoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicensePoolMutation, { data, loading, error }] = useCreateLicensePoolMutation({
 *   variables: {
 *      licensePool: // value for 'licensePool'
 *   },
 * });
 */
export function useCreateLicensePoolMutation(baseOptions?: Apollo.MutationHookOptions<CreateLicensePoolMutation, CreateLicensePoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLicensePoolMutation, CreateLicensePoolMutationVariables>(CreateLicensePoolDocument, options);
      }
export type CreateLicensePoolMutationHookResult = ReturnType<typeof useCreateLicensePoolMutation>;
export type CreateLicensePoolMutationResult = Apollo.MutationResult<CreateLicensePoolMutation>;
export type CreateLicensePoolMutationOptions = Apollo.BaseMutationOptions<CreateLicensePoolMutation, CreateLicensePoolMutationVariables>;
export const UpdateLicensePoolDocument = gql`
    mutation updateLicensePool($id: ID!, $licensePool: LicensePoolInput!) {
  updateLicensePool(id: $id, licensePool: $licensePool)
}
    `;
export type UpdateLicensePoolMutationFn = Apollo.MutationFunction<UpdateLicensePoolMutation, UpdateLicensePoolMutationVariables>;

/**
 * __useUpdateLicensePoolMutation__
 *
 * To run a mutation, you first call `useUpdateLicensePoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLicensePoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLicensePoolMutation, { data, loading, error }] = useUpdateLicensePoolMutation({
 *   variables: {
 *      id: // value for 'id'
 *      licensePool: // value for 'licensePool'
 *   },
 * });
 */
export function useUpdateLicensePoolMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLicensePoolMutation, UpdateLicensePoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLicensePoolMutation, UpdateLicensePoolMutationVariables>(UpdateLicensePoolDocument, options);
      }
export type UpdateLicensePoolMutationHookResult = ReturnType<typeof useUpdateLicensePoolMutation>;
export type UpdateLicensePoolMutationResult = Apollo.MutationResult<UpdateLicensePoolMutation>;
export type UpdateLicensePoolMutationOptions = Apollo.BaseMutationOptions<UpdateLicensePoolMutation, UpdateLicensePoolMutationVariables>;
export const DeleteLicensePoolDocument = gql`
    mutation deleteLicensePool($id: ID!) {
  deleteLicensePool(id: $id)
}
    `;
export type DeleteLicensePoolMutationFn = Apollo.MutationFunction<DeleteLicensePoolMutation, DeleteLicensePoolMutationVariables>;

/**
 * __useDeleteLicensePoolMutation__
 *
 * To run a mutation, you first call `useDeleteLicensePoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLicensePoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLicensePoolMutation, { data, loading, error }] = useDeleteLicensePoolMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLicensePoolMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLicensePoolMutation, DeleteLicensePoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLicensePoolMutation, DeleteLicensePoolMutationVariables>(DeleteLicensePoolDocument, options);
      }
export type DeleteLicensePoolMutationHookResult = ReturnType<typeof useDeleteLicensePoolMutation>;
export type DeleteLicensePoolMutationResult = Apollo.MutationResult<DeleteLicensePoolMutation>;
export type DeleteLicensePoolMutationOptions = Apollo.BaseMutationOptions<DeleteLicensePoolMutation, DeleteLicensePoolMutationVariables>;
export const LicenseTypesDocument = gql`
    query licenseTypes($paginationParams: PaginationParams) {
  licenseTypes(paginationInfo: $paginationParams) {
    total
    licenseTypes {
      activation_maximum
      date_created
      date_deleted
      description
      id
      type_key
    }
  }
}
    `;

/**
 * __useLicenseTypesQuery__
 *
 * To run a query within a React component, call `useLicenseTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseTypesQuery({
 *   variables: {
 *      paginationParams: // value for 'paginationParams'
 *   },
 * });
 */
export function useLicenseTypesQuery(baseOptions?: Apollo.QueryHookOptions<LicenseTypesQuery, LicenseTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicenseTypesQuery, LicenseTypesQueryVariables>(LicenseTypesDocument, options);
      }
export function useLicenseTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicenseTypesQuery, LicenseTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicenseTypesQuery, LicenseTypesQueryVariables>(LicenseTypesDocument, options);
        }
export type LicenseTypesQueryHookResult = ReturnType<typeof useLicenseTypesQuery>;
export type LicenseTypesLazyQueryHookResult = ReturnType<typeof useLicenseTypesLazyQuery>;
export type LicenseTypesQueryResult = Apollo.QueryResult<LicenseTypesQuery, LicenseTypesQueryVariables>;
export const LicenseTypeDocument = gql`
    query licenseType($id: ID!) {
  licenseType(id: $id) {
    activation_maximum
    description
    id
    type_key
  }
}
    `;

/**
 * __useLicenseTypeQuery__
 *
 * To run a query within a React component, call `useLicenseTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLicenseTypeQuery(baseOptions: Apollo.QueryHookOptions<LicenseTypeQuery, LicenseTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicenseTypeQuery, LicenseTypeQueryVariables>(LicenseTypeDocument, options);
      }
export function useLicenseTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicenseTypeQuery, LicenseTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicenseTypeQuery, LicenseTypeQueryVariables>(LicenseTypeDocument, options);
        }
export type LicenseTypeQueryHookResult = ReturnType<typeof useLicenseTypeQuery>;
export type LicenseTypeLazyQueryHookResult = ReturnType<typeof useLicenseTypeLazyQuery>;
export type LicenseTypeQueryResult = Apollo.QueryResult<LicenseTypeQuery, LicenseTypeQueryVariables>;
export const CreateLicenseTypeDocument = gql`
    mutation createLicenseType($licenseType: LicenseTypeInput!) {
  createLicenseType(licenseType: $licenseType)
}
    `;
export type CreateLicenseTypeMutationFn = Apollo.MutationFunction<CreateLicenseTypeMutation, CreateLicenseTypeMutationVariables>;

/**
 * __useCreateLicenseTypeMutation__
 *
 * To run a mutation, you first call `useCreateLicenseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicenseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicenseTypeMutation, { data, loading, error }] = useCreateLicenseTypeMutation({
 *   variables: {
 *      licenseType: // value for 'licenseType'
 *   },
 * });
 */
export function useCreateLicenseTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateLicenseTypeMutation, CreateLicenseTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLicenseTypeMutation, CreateLicenseTypeMutationVariables>(CreateLicenseTypeDocument, options);
      }
export type CreateLicenseTypeMutationHookResult = ReturnType<typeof useCreateLicenseTypeMutation>;
export type CreateLicenseTypeMutationResult = Apollo.MutationResult<CreateLicenseTypeMutation>;
export type CreateLicenseTypeMutationOptions = Apollo.BaseMutationOptions<CreateLicenseTypeMutation, CreateLicenseTypeMutationVariables>;
export const UpdateLicenseTypeDocument = gql`
    mutation updateLicenseType($id: ID!, $licenseType: LicenseTypeInput!) {
  updateLicenseType(id: $id, licenseType: $licenseType)
}
    `;
export type UpdateLicenseTypeMutationFn = Apollo.MutationFunction<UpdateLicenseTypeMutation, UpdateLicenseTypeMutationVariables>;

/**
 * __useUpdateLicenseTypeMutation__
 *
 * To run a mutation, you first call `useUpdateLicenseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLicenseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLicenseTypeMutation, { data, loading, error }] = useUpdateLicenseTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      licenseType: // value for 'licenseType'
 *   },
 * });
 */
export function useUpdateLicenseTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLicenseTypeMutation, UpdateLicenseTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLicenseTypeMutation, UpdateLicenseTypeMutationVariables>(UpdateLicenseTypeDocument, options);
      }
export type UpdateLicenseTypeMutationHookResult = ReturnType<typeof useUpdateLicenseTypeMutation>;
export type UpdateLicenseTypeMutationResult = Apollo.MutationResult<UpdateLicenseTypeMutation>;
export type UpdateLicenseTypeMutationOptions = Apollo.BaseMutationOptions<UpdateLicenseTypeMutation, UpdateLicenseTypeMutationVariables>;
export const DeleteLicenseTypeDocument = gql`
    mutation deleteLicenseType($id: ID!) {
  deleteLicenseType(id: $id)
}
    `;
export type DeleteLicenseTypeMutationFn = Apollo.MutationFunction<DeleteLicenseTypeMutation, DeleteLicenseTypeMutationVariables>;

/**
 * __useDeleteLicenseTypeMutation__
 *
 * To run a mutation, you first call `useDeleteLicenseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLicenseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLicenseTypeMutation, { data, loading, error }] = useDeleteLicenseTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLicenseTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLicenseTypeMutation, DeleteLicenseTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLicenseTypeMutation, DeleteLicenseTypeMutationVariables>(DeleteLicenseTypeDocument, options);
      }
export type DeleteLicenseTypeMutationHookResult = ReturnType<typeof useDeleteLicenseTypeMutation>;
export type DeleteLicenseTypeMutationResult = Apollo.MutationResult<DeleteLicenseTypeMutation>;
export type DeleteLicenseTypeMutationOptions = Apollo.BaseMutationOptions<DeleteLicenseTypeMutation, DeleteLicenseTypeMutationVariables>;
export const GenerateLicenseKeyDocument = gql`
    query generateLicenseKey {
  generateLicenseKey
}
    `;

/**
 * __useGenerateLicenseKeyQuery__
 *
 * To run a query within a React component, call `useGenerateLicenseKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateLicenseKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateLicenseKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenerateLicenseKeyQuery(baseOptions?: Apollo.QueryHookOptions<GenerateLicenseKeyQuery, GenerateLicenseKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateLicenseKeyQuery, GenerateLicenseKeyQueryVariables>(GenerateLicenseKeyDocument, options);
      }
export function useGenerateLicenseKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateLicenseKeyQuery, GenerateLicenseKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateLicenseKeyQuery, GenerateLicenseKeyQueryVariables>(GenerateLicenseKeyDocument, options);
        }
export type GenerateLicenseKeyQueryHookResult = ReturnType<typeof useGenerateLicenseKeyQuery>;
export type GenerateLicenseKeyLazyQueryHookResult = ReturnType<typeof useGenerateLicenseKeyLazyQuery>;
export type GenerateLicenseKeyQueryResult = Apollo.QueryResult<GenerateLicenseKeyQuery, GenerateLicenseKeyQueryVariables>;
export const GrantLicenseDocument = gql`
    mutation grantLicense($grantLicense: GrantOrUpdateLicense!) {
  grantLicense(params: $grantLicense)
}
    `;
export type GrantLicenseMutationFn = Apollo.MutationFunction<GrantLicenseMutation, GrantLicenseMutationVariables>;

/**
 * __useGrantLicenseMutation__
 *
 * To run a mutation, you first call `useGrantLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantLicenseMutation, { data, loading, error }] = useGrantLicenseMutation({
 *   variables: {
 *      grantLicense: // value for 'grantLicense'
 *   },
 * });
 */
export function useGrantLicenseMutation(baseOptions?: Apollo.MutationHookOptions<GrantLicenseMutation, GrantLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GrantLicenseMutation, GrantLicenseMutationVariables>(GrantLicenseDocument, options);
      }
export type GrantLicenseMutationHookResult = ReturnType<typeof useGrantLicenseMutation>;
export type GrantLicenseMutationResult = Apollo.MutationResult<GrantLicenseMutation>;
export type GrantLicenseMutationOptions = Apollo.BaseMutationOptions<GrantLicenseMutation, GrantLicenseMutationVariables>;
export const BatchGrantLicensesDocument = gql`
    mutation batchGrantLicenses($batchGrantLicense: BatchGrantLicense!) {
  batchGrantLicenses(params: $batchGrantLicense)
}
    `;
export type BatchGrantLicensesMutationFn = Apollo.MutationFunction<BatchGrantLicensesMutation, BatchGrantLicensesMutationVariables>;

/**
 * __useBatchGrantLicensesMutation__
 *
 * To run a mutation, you first call `useBatchGrantLicensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchGrantLicensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchGrantLicensesMutation, { data, loading, error }] = useBatchGrantLicensesMutation({
 *   variables: {
 *      batchGrantLicense: // value for 'batchGrantLicense'
 *   },
 * });
 */
export function useBatchGrantLicensesMutation(baseOptions?: Apollo.MutationHookOptions<BatchGrantLicensesMutation, BatchGrantLicensesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BatchGrantLicensesMutation, BatchGrantLicensesMutationVariables>(BatchGrantLicensesDocument, options);
      }
export type BatchGrantLicensesMutationHookResult = ReturnType<typeof useBatchGrantLicensesMutation>;
export type BatchGrantLicensesMutationResult = Apollo.MutationResult<BatchGrantLicensesMutation>;
export type BatchGrantLicensesMutationOptions = Apollo.BaseMutationOptions<BatchGrantLicensesMutation, BatchGrantLicensesMutationVariables>;
export const ActivateLicenseDocument = gql`
    mutation activateLicense($licenseKey: String!, $metadata: LicenseMetadata!) {
  activateLicense(license_key: $licenseKey, metadata: $metadata)
}
    `;
export type ActivateLicenseMutationFn = Apollo.MutationFunction<ActivateLicenseMutation, ActivateLicenseMutationVariables>;

/**
 * __useActivateLicenseMutation__
 *
 * To run a mutation, you first call `useActivateLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateLicenseMutation, { data, loading, error }] = useActivateLicenseMutation({
 *   variables: {
 *      licenseKey: // value for 'licenseKey'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useActivateLicenseMutation(baseOptions?: Apollo.MutationHookOptions<ActivateLicenseMutation, ActivateLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateLicenseMutation, ActivateLicenseMutationVariables>(ActivateLicenseDocument, options);
      }
export type ActivateLicenseMutationHookResult = ReturnType<typeof useActivateLicenseMutation>;
export type ActivateLicenseMutationResult = Apollo.MutationResult<ActivateLicenseMutation>;
export type ActivateLicenseMutationOptions = Apollo.BaseMutationOptions<ActivateLicenseMutation, ActivateLicenseMutationVariables>;
export const DeactivateLicenseDocument = gql`
    mutation deactivateLicense($id: ID!) {
  deactivateLicenseByInstanceID(id: $id)
}
    `;
export type DeactivateLicenseMutationFn = Apollo.MutationFunction<DeactivateLicenseMutation, DeactivateLicenseMutationVariables>;

/**
 * __useDeactivateLicenseMutation__
 *
 * To run a mutation, you first call `useDeactivateLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateLicenseMutation, { data, loading, error }] = useDeactivateLicenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateLicenseMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateLicenseMutation, DeactivateLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateLicenseMutation, DeactivateLicenseMutationVariables>(DeactivateLicenseDocument, options);
      }
export type DeactivateLicenseMutationHookResult = ReturnType<typeof useDeactivateLicenseMutation>;
export type DeactivateLicenseMutationResult = Apollo.MutationResult<DeactivateLicenseMutation>;
export type DeactivateLicenseMutationOptions = Apollo.BaseMutationOptions<DeactivateLicenseMutation, DeactivateLicenseMutationVariables>;
export const DeactivateLicenseByUuidDocument = gql`
    mutation deactivateLicenseByUuid($id: ID!) {
  deactivateLicenseByUuid(id: $id)
}
    `;
export type DeactivateLicenseByUuidMutationFn = Apollo.MutationFunction<DeactivateLicenseByUuidMutation, DeactivateLicenseByUuidMutationVariables>;

/**
 * __useDeactivateLicenseByUuidMutation__
 *
 * To run a mutation, you first call `useDeactivateLicenseByUuidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateLicenseByUuidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateLicenseByUuidMutation, { data, loading, error }] = useDeactivateLicenseByUuidMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateLicenseByUuidMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateLicenseByUuidMutation, DeactivateLicenseByUuidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateLicenseByUuidMutation, DeactivateLicenseByUuidMutationVariables>(DeactivateLicenseByUuidDocument, options);
      }
export type DeactivateLicenseByUuidMutationHookResult = ReturnType<typeof useDeactivateLicenseByUuidMutation>;
export type DeactivateLicenseByUuidMutationResult = Apollo.MutationResult<DeactivateLicenseByUuidMutation>;
export type DeactivateLicenseByUuidMutationOptions = Apollo.BaseMutationOptions<DeactivateLicenseByUuidMutation, DeactivateLicenseByUuidMutationVariables>;
export const EditLicenseDocument = gql`
    mutation editLicense($id: ID!, $grantLicense: GrantOrUpdateLicense!) {
  editLicense(licenseID: $id, params: $grantLicense)
}
    `;
export type EditLicenseMutationFn = Apollo.MutationFunction<EditLicenseMutation, EditLicenseMutationVariables>;

/**
 * __useEditLicenseMutation__
 *
 * To run a mutation, you first call `useEditLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLicenseMutation, { data, loading, error }] = useEditLicenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      grantLicense: // value for 'grantLicense'
 *   },
 * });
 */
export function useEditLicenseMutation(baseOptions?: Apollo.MutationHookOptions<EditLicenseMutation, EditLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditLicenseMutation, EditLicenseMutationVariables>(EditLicenseDocument, options);
      }
export type EditLicenseMutationHookResult = ReturnType<typeof useEditLicenseMutation>;
export type EditLicenseMutationResult = Apollo.MutationResult<EditLicenseMutation>;
export type EditLicenseMutationOptions = Apollo.BaseMutationOptions<EditLicenseMutation, EditLicenseMutationVariables>;
export const DeleteLicenseDocument = gql`
    mutation deleteLicense($id: ID!) {
  deleteLicense(id: $id)
}
    `;
export type DeleteLicenseMutationFn = Apollo.MutationFunction<DeleteLicenseMutation, DeleteLicenseMutationVariables>;

/**
 * __useDeleteLicenseMutation__
 *
 * To run a mutation, you first call `useDeleteLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLicenseMutation, { data, loading, error }] = useDeleteLicenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLicenseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLicenseMutation, DeleteLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLicenseMutation, DeleteLicenseMutationVariables>(DeleteLicenseDocument, options);
      }
export type DeleteLicenseMutationHookResult = ReturnType<typeof useDeleteLicenseMutation>;
export type DeleteLicenseMutationResult = Apollo.MutationResult<DeleteLicenseMutation>;
export type DeleteLicenseMutationOptions = Apollo.BaseMutationOptions<DeleteLicenseMutation, DeleteLicenseMutationVariables>;
export const LicenseDocument = gql`
    query license($id: ID!) {
  license(id: $id) {
    activation_maximum
    changed_by
    client_user
    customer {
      id
      contact {
        first_name
        last_name
        company
      }
    }
    date_created
    date_deleted
    date_updated
    id
    license_key
    merchant {
      id
      contact {
        first_name
        last_name
        company
      }
    }
    nActivations
    orderNumber
    note
    pools {
      creator_user_mail
      date_created
      date_deleted
      id
      license_type_key
      n_available
      group_id
      n_in_use
      note
      pool_size
    }
  }
}
    `;

/**
 * __useLicenseQuery__
 *
 * To run a query within a React component, call `useLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLicenseQuery(baseOptions: Apollo.QueryHookOptions<LicenseQuery, LicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicenseQuery, LicenseQueryVariables>(LicenseDocument, options);
      }
export function useLicenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicenseQuery, LicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicenseQuery, LicenseQueryVariables>(LicenseDocument, options);
        }
export type LicenseQueryHookResult = ReturnType<typeof useLicenseQuery>;
export type LicenseLazyQueryHookResult = ReturnType<typeof useLicenseLazyQuery>;
export type LicenseQueryResult = Apollo.QueryResult<LicenseQuery, LicenseQueryVariables>;
export const LicensesDocument = gql`
    query licenses($paginationInfo: PaginationParams, $filter: LicenseFilterUpdater) {
  licenses(paginationInfo: $paginationInfo, filter: $filter) {
    total
    licenses {
      activation_maximum
      changed_by
      client_user
      customer {
        id
        contact {
          first_name
          last_name
          customerid
          company
        }
      }
      date_created
      date_deleted
      date_updated
      id
      license_key
      merchant {
        id
        contact {
          first_name
          last_name
          company
        }
      }
      nActivations
      orderNumber
      note
      pools {
        id
        pool_size
        n_in_use
        license_type_key
      }
    }
  }
}
    `;

/**
 * __useLicensesQuery__
 *
 * To run a query within a React component, call `useLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicensesQuery({
 *   variables: {
 *      paginationInfo: // value for 'paginationInfo'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLicensesQuery(baseOptions?: Apollo.QueryHookOptions<LicensesQuery, LicensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicensesQuery, LicensesQueryVariables>(LicensesDocument, options);
      }
export function useLicensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicensesQuery, LicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicensesQuery, LicensesQueryVariables>(LicensesDocument, options);
        }
export type LicensesQueryHookResult = ReturnType<typeof useLicensesQuery>;
export type LicensesLazyQueryHookResult = ReturnType<typeof useLicensesLazyQuery>;
export type LicensesQueryResult = Apollo.QueryResult<LicensesQuery, LicensesQueryVariables>;
export const EndUserLicenseActivationsDocument = gql`
    query endUserLicenseActivations {
  endUserLicenseActivations {
    date
    id
    license_key
    types
  }
}
    `;

/**
 * __useEndUserLicenseActivationsQuery__
 *
 * To run a query within a React component, call `useEndUserLicenseActivationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEndUserLicenseActivationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEndUserLicenseActivationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEndUserLicenseActivationsQuery(baseOptions?: Apollo.QueryHookOptions<EndUserLicenseActivationsQuery, EndUserLicenseActivationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EndUserLicenseActivationsQuery, EndUserLicenseActivationsQueryVariables>(EndUserLicenseActivationsDocument, options);
      }
export function useEndUserLicenseActivationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EndUserLicenseActivationsQuery, EndUserLicenseActivationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EndUserLicenseActivationsQuery, EndUserLicenseActivationsQueryVariables>(EndUserLicenseActivationsDocument, options);
        }
export type EndUserLicenseActivationsQueryHookResult = ReturnType<typeof useEndUserLicenseActivationsQuery>;
export type EndUserLicenseActivationsLazyQueryHookResult = ReturnType<typeof useEndUserLicenseActivationsLazyQuery>;
export type EndUserLicenseActivationsQueryResult = Apollo.QueryResult<EndUserLicenseActivationsQuery, EndUserLicenseActivationsQueryVariables>;
export const LicenseInstancesDocument = gql`
    query licenseInstances($paginationInfo: PaginationParams, $licenseID: ID) {
  licenseInstances(licenseID: $licenseID, paginationInfo: $paginationInfo) {
    total
    instances {
      date_created
      date_deleted
      system_identifiers
      id
      license_instance_key
      user_id
      contact {
        id
        first_name
        last_name
        company
        street
        post_code
        city
        country_code
        note
        customerid
        email
      }
      license {
        pools {
          id
          license_type_key
          pool_size
          date_deleted
          date_created
          note
        }
        activation_maximum
        customer {
          id
          contact {
            id
            first_name
            last_name
            company
            street
            post_code
            city
            country_code
            note
            customerid
            email
          }
        }
        id
        license_key
        merchant {
          id
          contact {
            id
            first_name
            last_name
            company
            street
            post_code
            city
            country_code
            note
            customerid
            email
          }
        }
        nActivations
        orderNumber
        note
      }
    }
  }
}
    `;

/**
 * __useLicenseInstancesQuery__
 *
 * To run a query within a React component, call `useLicenseInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseInstancesQuery({
 *   variables: {
 *      paginationInfo: // value for 'paginationInfo'
 *      licenseID: // value for 'licenseID'
 *   },
 * });
 */
export function useLicenseInstancesQuery(baseOptions?: Apollo.QueryHookOptions<LicenseInstancesQuery, LicenseInstancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicenseInstancesQuery, LicenseInstancesQueryVariables>(LicenseInstancesDocument, options);
      }
export function useLicenseInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicenseInstancesQuery, LicenseInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicenseInstancesQuery, LicenseInstancesQueryVariables>(LicenseInstancesDocument, options);
        }
export type LicenseInstancesQueryHookResult = ReturnType<typeof useLicenseInstancesQuery>;
export type LicenseInstancesLazyQueryHookResult = ReturnType<typeof useLicenseInstancesLazyQuery>;
export type LicenseInstancesQueryResult = Apollo.QueryResult<LicenseInstancesQuery, LicenseInstancesQueryVariables>;
export const GetAllLicenseInstancesDocument = gql`
    query getAllLicenseInstances($paginationInfo: PaginationParams, $fulltext: String) {
  getAllLicenseInstances(fulltext: $fulltext, paginationInfo: $paginationInfo) {
    total
    instances {
      date_created
      date_deleted
      system_identifiers
      id
      license_instance_key
      user_id
      contact {
        id
        first_name
        last_name
        company
        street
        post_code
        city
        country_code
        note
        customerid
        email
      }
      license {
        pools {
          id
          license_type_key
          pool_size
          date_deleted
          date_created
          note
        }
        activation_maximum
        customer {
          id
          contact {
            id
            first_name
            last_name
            company
            street
            post_code
            city
            country_code
            note
            customerid
            email
          }
        }
        id
        license_key
        merchant {
          id
          contact {
            id
            first_name
            last_name
            company
            street
            post_code
            city
            country_code
            note
            customerid
            email
          }
        }
        nActivations
        orderNumber
        note
      }
    }
  }
}
    `;

/**
 * __useGetAllLicenseInstancesQuery__
 *
 * To run a query within a React component, call `useGetAllLicenseInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLicenseInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLicenseInstancesQuery({
 *   variables: {
 *      paginationInfo: // value for 'paginationInfo'
 *      fulltext: // value for 'fulltext'
 *   },
 * });
 */
export function useGetAllLicenseInstancesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllLicenseInstancesQuery, GetAllLicenseInstancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllLicenseInstancesQuery, GetAllLicenseInstancesQueryVariables>(GetAllLicenseInstancesDocument, options);
      }
export function useGetAllLicenseInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllLicenseInstancesQuery, GetAllLicenseInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllLicenseInstancesQuery, GetAllLicenseInstancesQueryVariables>(GetAllLicenseInstancesDocument, options);
        }
export type GetAllLicenseInstancesQueryHookResult = ReturnType<typeof useGetAllLicenseInstancesQuery>;
export type GetAllLicenseInstancesLazyQueryHookResult = ReturnType<typeof useGetAllLicenseInstancesLazyQuery>;
export type GetAllLicenseInstancesQueryResult = Apollo.QueryResult<GetAllLicenseInstancesQuery, GetAllLicenseInstancesQueryVariables>;
export const CreateMerchantDocument = gql`
    mutation createMerchant($contact: ContactInput!) {
  createMerchant(contact: $contact)
}
    `;
export type CreateMerchantMutationFn = Apollo.MutationFunction<CreateMerchantMutation, CreateMerchantMutationVariables>;

/**
 * __useCreateMerchantMutation__
 *
 * To run a mutation, you first call `useCreateMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMerchantMutation, { data, loading, error }] = useCreateMerchantMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useCreateMerchantMutation(baseOptions?: Apollo.MutationHookOptions<CreateMerchantMutation, CreateMerchantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMerchantMutation, CreateMerchantMutationVariables>(CreateMerchantDocument, options);
      }
export type CreateMerchantMutationHookResult = ReturnType<typeof useCreateMerchantMutation>;
export type CreateMerchantMutationResult = Apollo.MutationResult<CreateMerchantMutation>;
export type CreateMerchantMutationOptions = Apollo.BaseMutationOptions<CreateMerchantMutation, CreateMerchantMutationVariables>;
export const MerchantsDocument = gql`
    query merchants($paginationParams: PaginationParams, $filter: ContactFilter) {
  merchants(paginationInfo: $paginationParams, filter: $filter) {
    total
    merchants {
      id
      nLicenses
      contact {
        city
        company
        country_code
        email
        fax
        first_name
        last_name
        middle_name
        mobile
        note
        phone
        position
        post_code
        salutation
        state
        state
        street
        website
        customerid
      }
    }
  }
}
    `;

/**
 * __useMerchantsQuery__
 *
 * To run a query within a React component, call `useMerchantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantsQuery({
 *   variables: {
 *      paginationParams: // value for 'paginationParams'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMerchantsQuery(baseOptions?: Apollo.QueryHookOptions<MerchantsQuery, MerchantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MerchantsQuery, MerchantsQueryVariables>(MerchantsDocument, options);
      }
export function useMerchantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantsQuery, MerchantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MerchantsQuery, MerchantsQueryVariables>(MerchantsDocument, options);
        }
export type MerchantsQueryHookResult = ReturnType<typeof useMerchantsQuery>;
export type MerchantsLazyQueryHookResult = ReturnType<typeof useMerchantsLazyQuery>;
export type MerchantsQueryResult = Apollo.QueryResult<MerchantsQuery, MerchantsQueryVariables>;
export const MerchantsBasicInfoDocument = gql`
    query merchantsBasicInfo {
  merchants {
    total
    merchants {
      id
      contact {
        first_name
        last_name
        company
        customerid
      }
    }
  }
}
    `;

/**
 * __useMerchantsBasicInfoQuery__
 *
 * To run a query within a React component, call `useMerchantsBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantsBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantsBasicInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMerchantsBasicInfoQuery(baseOptions?: Apollo.QueryHookOptions<MerchantsBasicInfoQuery, MerchantsBasicInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MerchantsBasicInfoQuery, MerchantsBasicInfoQueryVariables>(MerchantsBasicInfoDocument, options);
      }
export function useMerchantsBasicInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantsBasicInfoQuery, MerchantsBasicInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MerchantsBasicInfoQuery, MerchantsBasicInfoQueryVariables>(MerchantsBasicInfoDocument, options);
        }
export type MerchantsBasicInfoQueryHookResult = ReturnType<typeof useMerchantsBasicInfoQuery>;
export type MerchantsBasicInfoLazyQueryHookResult = ReturnType<typeof useMerchantsBasicInfoLazyQuery>;
export type MerchantsBasicInfoQueryResult = Apollo.QueryResult<MerchantsBasicInfoQuery, MerchantsBasicInfoQueryVariables>;
export const UpdateMerchantDocument = gql`
    mutation updateMerchant($id: ID!, $contact: ContactInput!) {
  updateMerchant(id: $id, contact: $contact)
}
    `;
export type UpdateMerchantMutationFn = Apollo.MutationFunction<UpdateMerchantMutation, UpdateMerchantMutationVariables>;

/**
 * __useUpdateMerchantMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantMutation, { data, loading, error }] = useUpdateMerchantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useUpdateMerchantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMerchantMutation, UpdateMerchantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMerchantMutation, UpdateMerchantMutationVariables>(UpdateMerchantDocument, options);
      }
export type UpdateMerchantMutationHookResult = ReturnType<typeof useUpdateMerchantMutation>;
export type UpdateMerchantMutationResult = Apollo.MutationResult<UpdateMerchantMutation>;
export type UpdateMerchantMutationOptions = Apollo.BaseMutationOptions<UpdateMerchantMutation, UpdateMerchantMutationVariables>;
export const MerchantDocument = gql`
    query merchant($id: ID!) {
  merchant(id: $id) {
    nLicenses
    id
    contact {
      city
      company
      country_code
      email
      fax
      first_name
      last_name
      middle_name
      mobile
      note
      phone
      position
      post_code
      salutation
      state
      state
      street
      website
      customerid
    }
  }
}
    `;

/**
 * __useMerchantQuery__
 *
 * To run a query within a React component, call `useMerchantQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMerchantQuery(baseOptions: Apollo.QueryHookOptions<MerchantQuery, MerchantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MerchantQuery, MerchantQueryVariables>(MerchantDocument, options);
      }
export function useMerchantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantQuery, MerchantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MerchantQuery, MerchantQueryVariables>(MerchantDocument, options);
        }
export type MerchantQueryHookResult = ReturnType<typeof useMerchantQuery>;
export type MerchantLazyQueryHookResult = ReturnType<typeof useMerchantLazyQuery>;
export type MerchantQueryResult = Apollo.QueryResult<MerchantQuery, MerchantQueryVariables>;
export const DeleteMerchantDocument = gql`
    mutation deleteMerchant($id: ID!) {
  deleteMerchant(id: $id)
}
    `;
export type DeleteMerchantMutationFn = Apollo.MutationFunction<DeleteMerchantMutation, DeleteMerchantMutationVariables>;

/**
 * __useDeleteMerchantMutation__
 *
 * To run a mutation, you first call `useDeleteMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMerchantMutation, { data, loading, error }] = useDeleteMerchantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMerchantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMerchantMutation, DeleteMerchantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMerchantMutation, DeleteMerchantMutationVariables>(DeleteMerchantDocument, options);
      }
export type DeleteMerchantMutationHookResult = ReturnType<typeof useDeleteMerchantMutation>;
export type DeleteMerchantMutationResult = Apollo.MutationResult<DeleteMerchantMutation>;
export type DeleteMerchantMutationOptions = Apollo.BaseMutationOptions<DeleteMerchantMutation, DeleteMerchantMutationVariables>;
export const GetUserProfilesDocument = gql`
    query getUserProfiles($userIds: [String]) {
  getUserProfiles(userIds: $userIds) {
    userId
    Title
    FirstName
    LastName
    CompanyName
    Phone
    Mobile
    Fax
    Email
    Street
    Zip
    City
    Country
    Newsletter
    Department
    Relationship
    TermsOfUse
  }
}
    `;

/**
 * __useGetUserProfilesQuery__
 *
 * To run a query within a React component, call `useGetUserProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfilesQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useGetUserProfilesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfilesQuery, GetUserProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfilesQuery, GetUserProfilesQueryVariables>(GetUserProfilesDocument, options);
      }
export function useGetUserProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfilesQuery, GetUserProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfilesQuery, GetUserProfilesQueryVariables>(GetUserProfilesDocument, options);
        }
export type GetUserProfilesQueryHookResult = ReturnType<typeof useGetUserProfilesQuery>;
export type GetUserProfilesLazyQueryHookResult = ReturnType<typeof useGetUserProfilesLazyQuery>;
export type GetUserProfilesQueryResult = Apollo.QueryResult<GetUserProfilesQuery, GetUserProfilesQueryVariables>;
export const UsersDocument = gql`
    query users($paginationParams: PaginationParams, $groupID: ID) {
  users(paginationInfo: $paginationParams, groupID: $groupID) {
    total
    users {
      id
      email
      roles
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      paginationParams: // value for 'paginationParams'
 *      groupID: // value for 'groupID'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserOfGroupDocument = gql`
    query userOfGroup($id: ID!) {
  userOfGroup(id: $id) {
    email
    id
    group {
      id
      name
    }
  }
}
    `;

/**
 * __useUserOfGroupQuery__
 *
 * To run a query within a React component, call `useUserOfGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOfGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOfGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserOfGroupQuery(baseOptions: Apollo.QueryHookOptions<UserOfGroupQuery, UserOfGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserOfGroupQuery, UserOfGroupQueryVariables>(UserOfGroupDocument, options);
      }
export function useUserOfGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserOfGroupQuery, UserOfGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserOfGroupQuery, UserOfGroupQueryVariables>(UserOfGroupDocument, options);
        }
export type UserOfGroupQueryHookResult = ReturnType<typeof useUserOfGroupQuery>;
export type UserOfGroupLazyQueryHookResult = ReturnType<typeof useUserOfGroupLazyQuery>;
export type UserOfGroupQueryResult = Apollo.QueryResult<UserOfGroupQuery, UserOfGroupQueryVariables>;