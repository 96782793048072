import React, { FC, ReactElement, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { CircularProgress, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { Text } from '../../partials/Inputs/Text'
import { useTranslation } from '../../../hooks/useTranslation'
import { PageHeading } from '../../partials/PageHeading'
import { FixedActionBar } from '@csinstruments/cs-react-theme'
import { AccountCircle, Forward } from '@material-ui/icons'
import {
  ContactFilter,
  LicenseInstance,
  LicensePoolPageEntry,
  LicenseQuery,
  PaginationParams,
  useCustomerQuery,
  useCustomersQuery,
  useEditLicenseMutation,
  useGenerateLicenseKeyQuery,
  useGrantLicenseMutation,
  useLicenseInstancesQuery,
  useLicensePoolsQuery,
  useLicenseQuery,
  useMerchantQuery,
  useMerchantsQuery,
} from '../../../../api/models'
import { Dropdown } from '../../partials/Inputs/Dropdown'
import { BasicContactInfo } from '../../../hooks/useBasicContactInfo'
import { ResultAlert } from '../../partials/ResultAlert'
import { useHistory, useParams } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import { JournalTable } from '../Journal/JournalTable'
import { LicenseInstancesTable } from './LicenseInstancesTable'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { Autocomplete } from '@material-ui/lab'
import { useQueryParam } from '../../../hooks/useQueryParam'

type GrantLicenseFormValues = {
  poolStrings: string[]
  customer?: BasicContactInfo
  merchant?: BasicContactInfo
  maxActivations?: string
  note?: string
  orderNumber?: string
}

type GrantOrUpdateLicenseParams = {
  id: string
  poolID: string
}

export function licensePoolEntryToString(e: LicensePoolPageEntry | undefined) {
  return !e ? '' : `${e.license_type_key || ''} [${e.n_in_use ?? '∞'}/${e.pool_size ? e.pool_size : '∞'}]`
}

export const licensePoolEntriesToStrings = (entries: Array<LicensePoolPageEntry> | undefined): string[] => {
  return !entries
    ? []
    : entries
        .filter((e) => e.n_available === null || e.n_available === undefined || e.n_available > 0)
        .map(licensePoolEntryToString)
}

type AutocompleteItem = {
  id: string
  name: string
}

enum AutocompleteComponentContext {
  Customers = 'CUSTOMERS',
  Merchants = 'MERCHANTS',
}

type AutocompleteComponentProps = {
  context: AutocompleteComponentContext
  setId: (id: string) => void
  license?: LicenseQuery
}

export const convertData = (items: any): AutocompleteItem[] => {
  return items.map((c: any) => {
    return {
      id: c.id,
      name: `${c.contact?.first_name || ''} ${c.contact?.last_name || ''} // ${c.contact?.customerid || ''} // ${
        c.contact?.company || ''
      }`,
    }
  })
}

const AutocompleteComponent: FC<AutocompleteComponentProps> = ({ context, setId, license }): ReactElement => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const defaultAutocompleteItem = {} as AutocompleteItem
  const [selectAutocompleteItem, setSelectAutocompleteItem] = useState<AutocompleteItem>(defaultAutocompleteItem)
  const [autocompleteItems, setAutocompleteItems] = useState<AutocompleteItem[]>([defaultAutocompleteItem])
  const [phrase, setPhrase] = useState<string>()

  const paginationData: PaginationParams = { limit: 10, Offset: 0 }
  const [filter, setFilter] = useState<ContactFilter>({})
  const { data: customers } = useCustomersQuery({
    skip: context === AutocompleteComponentContext.Merchants,
    variables: { paginationParams: paginationData, filter },
  })
  const { data: merchants } = useMerchantsQuery({
    skip: context === AutocompleteComponentContext.Customers,
    variables: { paginationParams: paginationData, filter },
  })

  const merchantId = useQueryParam('merchant')
  const customerId = useQueryParam('customer')

  const { data: customer } = useCustomerQuery({
    skip: !customerId,
    variables: { id: customerId },
  })
  const { data: merchant } = useMerchantQuery({
    skip: !merchantId,
    variables: { id: merchantId },
  })

  useEffect(() => {
    if (open && autocompleteItems?.length === 0) {
      setLoading(true)
    }
  }, [open, autocompleteItems])

  useEffect(() => {
    if (!loading) {
      return undefined
    }
  }, [loading])

  useEffect(() => {
    if (!open) {
      setAutocompleteItems([])
    }
  }, [open])

  useEffect(() => {
    setFilter({ fulltext: phrase })
  }, [phrase])

  useEffect(() => {
    const items: any = customers?.customers?.customers
      ? customers?.customers?.customers
      : merchants?.merchants?.merchants || []
    if (items.length >= 1) {
      const transformedItems: AutocompleteItem[] = convertData(items) || [defaultAutocompleteItem]
      setAutocompleteItems(transformedItems)
    }
  }, [customers, merchants])

  const handleOnChange = (value: any) => {
    setId(value?.id)
    setSelectAutocompleteItem(value)
  }

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhrase(event.target.value)
  }

  const handleKeyPress = (event: any) => {
    if (event.keyCode === 13) {
      setPhrase(event.target.value)
    }
  }

  if (license) {
    return (
      <>
        {context === AutocompleteComponentContext.Customers && license?.license?.customer && (
          <Typography>{convertData([license.license.customer])[0].name}</Typography>
        )}
        {context === AutocompleteComponentContext.Merchants && license?.license?.merchant && (
          <Typography>{convertData([license.license.merchant])[0].name}</Typography>
        )}
      </>
    )
  }
  if (customer || merchant) {
    return (
      <>
        {context === AutocompleteComponentContext.Customers && customer && (
          <Typography>{convertData([customer?.customer])[0].name}</Typography>
        )}
        {context === AutocompleteComponentContext.Merchants && merchant && (
          <Typography>{convertData([merchant?.merchant])[0].name}</Typography>
        )}
      </>
    )
  }

  return (
    <Autocomplete
      freeSolo
      filterOptions={(autocompleteItems, state) => autocompleteItems}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onChange={(event, value) => handleOnChange(value)}
      getOptionLabel={(autocompleteItem) => autocompleteItem.name || ''}
      options={autocompleteItems}
      loading={loading}
      renderInput={(params) => (
        <TextField
          autoFocus
          {...params}
          variant="outlined"
          onChange={handleTextFieldChange}
          onKeyDown={handleKeyPress}
          label={context === AutocompleteComponentContext.Customers ? t('objects.customers') : t('objects.merchants')}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export const GrantOrUpdateLicense: FC = ({}): ReactElement => {
  const { t } = useTranslation()
  const [preselectedLicensePoolsValue, setPreselectedLicensePoolsValue] = useState<string[]>([])
  const [showFailAlert, setShowFailAlert] = useState(false)
  const [grantLicenseMutation, { loading: grantLoading }] = useGrantLicenseMutation()
  const [editLicenseMutation, { loading: editLoading }] = useEditLicenseMutation()
  const { id, poolID } = useParams<GrantOrUpdateLicenseParams>()
  const preselectedCustomerId = useQueryParam('customer')
  const preselectedMerchantId = useQueryParam('merchant')
  const [customerId, setCustomerId] = useState<string>(preselectedCustomerId)
  const [merchantId, setMerchantId] = useState<string>(preselectedMerchantId)

  const { data: licensePoolsData } = useLicensePoolsQuery({
    variables: {},
  })
  const { data: licenseData } = useLicenseQuery({
    variables: { id },
    skip: !id,
  })

  const { data: licenseInstances, refetch: refetchLicenseInstances } = useLicenseInstancesQuery({
    variables: { licenseID: id },
    skip: !id,
  })

  const [formValues, setFormValues] = useState<GrantLicenseFormValues>({
    customer: { id: '', name: '' },
    merchant: { id: '', name: '' },
    poolStrings: [],
    maxActivations: '2',
  })

  const history = useHistory()

  const { data: keyData } = useGenerateLicenseKeyQuery()

  const key = useMemo(() => (licenseData ? licenseData.license.license_key : keyData?.generateLicenseKey || ''), [
    keyData,
    licenseData,
  ])

  const licensePoolStrings = useMemo(() => licensePoolEntriesToStrings(licensePoolsData?.licensePools.entries), [
    licensePoolsData?.licensePools.entries,
  ])

  useEffect(() => {
    setFormValues((fv) => {
      return {
        ...fv,
        note: licenseData?.license.note || '',
        orderNumber: licenseData?.license?.orderNumber || '',
        maxActivations: licenseData?.license.activation_maximum
          ? `${licenseData?.license.activation_maximum}`
          : fv.maxActivations,
      }
    })

    setCustomerId(customerId || licenseData?.license?.customer?.id || '')
    setMerchantId(merchantId || licenseData?.license?.merchant?.id || '')

    let licensePoolsValue = licenseData ? licensePoolEntriesToStrings(licenseData?.license?.pools) : []
    if (!licensePoolsValue && poolID && licensePoolsData?.licensePools) {
      const pool = licensePoolsData?.licensePools.entries.find((pool) => pool.id === poolID)
      if (pool) {
        licensePoolsValue = [licensePoolEntryToString(pool)]
      }
    }
    setPreselectedLicensePoolsValue(licensePoolsValue)
  }, [licenseData])

  const [
    licenseKeyLabel,
    grantLicenseLabel,
    editLicenseLabel,
    licensePoolsLabel,
    grantLabel,
    merchantsLabel,
    customersLabel,
    maximumActivationsLabel,
    noteLabel,
    noneLabel,
    creationFailedLabel,
    orderNumberLabel,
  ] = useMemo(() => {
    return [
      t('licenses.licenseKey'),
      t('licenses.grantLicense'),
      t('licenses.editLicense'),
      t('objects.licensePools') + '*',
      t('actions.grant'),
      t('objects.merchants'),
      t('objects.customers'),
      t('licenses.maximumActivations') + '*',
      t('objects.note'),
      t('customers.none'),
      t('licenses.creationFailed'),
      t('objects.orderNumber'),
    ]
  }, [t])

  const allLicenseInstances = (licenseInstances?.licenseInstances.instances as unknown) as LicenseInstance[]

  const grantLicenseAction = useCallback(() => {
    if (!customerId && !merchantId) {
      setShowFailAlert(true)
      return
    } else if (formValues.poolStrings.length < 1 || !formValues.maxActivations) {
      setShowFailAlert(true)
      return
    }

    const pools = formValues.poolStrings
      .map((s) => licensePoolsData?.licensePools.entries.find((e) => licensePoolEntryToString(e) === s))
      .map((p) => p?.id)
      .filter((p) => p) as string[]

    const grantLicense = {
      customer: customerId || null,
      merchant: merchantId || null,
      note: formValues.note,
      maximumActivations: parseInt(formValues.maxActivations),
      licenceKey: key,
      license_pools: pools,
      orderNumber: formValues.orderNumber || '',
    }

    if (!id) {
      grantLicenseMutation({
        variables: {
          grantLicense,
        },
      })
        .then(() => {
          history.push(ROUTES.licenses)
        })
        .catch(() => {
          setShowFailAlert(true)
        })
    } else {
      editLicenseMutation({
        variables: {
          id,
          grantLicense,
        },
      })
        .then(() => {
          history.push(ROUTES.licenses)
        })
        .catch(() => {
          setShowFailAlert(true)
        })
    }
  }, [
    id,
    editLicenseMutation,
    history,
    grantLicenseMutation,
    noneLabel,
    formValues.merchant,
    formValues.customer,
    customerId,
    formValues.maxActivations,
    formValues.note,
    formValues.orderNumber,
    formValues.poolStrings,
    key,
    licensePoolsData?.licensePools.entries,
    merchantId,
  ])

  return (
    <>
      <ResultAlert
        alertText={creationFailedLabel}
        showAlert={showFailAlert}
        modifyShowAlert={setShowFailAlert}
        severity="warning"
      />
      <FixedActionBar
        labels={[grantLabel]}
        actions={[grantLicenseAction]}
        icons={[Forward]}
        buttonProps={[{ solid: true }]}
      />
      <PageHeading title={!id ? grantLicenseLabel : editLicenseLabel} />
      <Grid container spacing={3} style={{ paddingTop: 20, paddingBottom: 50 }}>
        <Grid item xs={12}>
          <ProgressBar show={grantLoading || editLoading} />
        </Grid>
        <Text
          variant="filled"
          label={licenseKeyLabel}
          columns={12}
          displayOnly={true}
          value={licenseData ? licenseData.license.license_key : key}
        />
        <Grid item xs={12}>
          <Dropdown
            label={licensePoolsLabel}
            preselect={preselectedLicensePoolsValue}
            values={licensePoolStrings}
            valueSelected={(s) => {
              setFormValues((fv) => {
                return { ...fv, poolStrings: s }
              })
            }}
            multiple={true}
          />
        </Grid>

        <Grid item xs={12} key={licenseData?.license?.id}>
          <AutocompleteComponent
            context={AutocompleteComponentContext.Customers}
            setId={setCustomerId}
            license={licenseData}
            key={licenseData?.license?.id}
          />
        </Grid>
        <Grid item xs={12} key={licenseData?.license?.id}>
          <AutocompleteComponent
            context={AutocompleteComponentContext.Merchants}
            setId={setMerchantId}
            license={licenseData}
            key={licenseData?.license?.id}
          />
        </Grid>
        <Text
          label={maximumActivationsLabel}
          columns={12}
          onlyNumbers
          value={formValues.maxActivations || ''}
          changeHandler={(maxActivations) =>
            setFormValues((formValues) => {
              return { ...formValues, maxActivations }
            })
          }
        />
        <Text
          label={noteLabel}
          columns={12}
          value={formValues.note || ''}
          changeHandler={(note) =>
            setFormValues((formValues) => {
              return { ...formValues, note }
            })
          }
        />
        <Text
          label={orderNumberLabel}
          columns={12}
          value={formValues.orderNumber || ''}
          changeHandler={(orderNumber) =>
            setFormValues((formValues) => {
              return { ...formValues, orderNumber }
            })
          }
        />

        {licenseInstances && (
          <LicenseInstancesTable
            licenseInstances={allLicenseInstances}
            refetch={refetchLicenseInstances}
            headline={`(${licenseData?.license.nActivations}/${licenseData?.license.activation_maximum})`}
          />
        )}
        {id && <JournalTable showTitle filter={{ license_id: id }} />}
      </Grid>
    </>
  )
}
