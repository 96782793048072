import { ApolloClient, DefaultOptions, createHttpLink, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client'
import { environment } from './environment'
import { setContext } from '@apollo/client/link/context'
import { getTokens } from './keycloakService'

export enum GraphQLEndpoints {
  Default = 'DEFAULT',
  UserProfileService = 'USER_PROFILE_SERVICE',
}

const defaultGraphQlEndpoint = new HttpLink({
  uri: `${environment.API.GQL_HTTP_URI}`,
})
const userProfileGraphQlEndpoint = new HttpLink({
  uri: `${environment.API.GQL_HTTP_URI_USER_PROFILE_SERVICE}`,
})

// eslint-disable-next-line
export const getApolloClient = (): ApolloClient<any> => {
  const cache = new InMemoryCache()
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const [token] = getTokens()
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  return new ApolloClient({
    link: ApolloLink.split(
      (operation) => operation.getContext().clientName === GraphQLEndpoints.UserProfileService,
      authLink.concat(userProfileGraphQlEndpoint),
      authLink.concat(defaultGraphQlEndpoint),
    ),
    cache,
    defaultOptions,
  })
}
