const checkForUndefinedVar = (variable: string | undefined) => {
  if (variable && variable === 'undefined') {
    return undefined
  }
  return variable
}

interface API {
  GQL_HTTP_URI: string
  GQL_HTTP_URI_USER_PROFILE_SERVICE: string
  GQL_WS_URI: string
  GQL_SCHEMA_URI: string
}

export interface Environment {
  BRAND: Brand
  APP_NAME: string
  APP_VERSION: string
  STAGE: string
  WEB_URL: string
  SENTRY_DSN: string
  DATA_SERVICE_URI: string
  LOGIN_URL: string
  LOGIN_REALM: string
  API: API
  CSV_EXPORT_URL: string
  LICENSE_SERVER_PUBLIC_KEY: string
}

export enum Brand {
  Cs = 'cs',
  Beko = 'beko',
  Testo = 'testo',
  Neutral = 'neutral',
}

export const environment: Environment = {
  BRAND: ((process.env.REACT_APP_BRAND as unknown) as Brand) || Brand.Cs,
  APP_NAME: process.env.REACT_APP_NAME || '',
  APP_VERSION: process.env.REACT_APP_VERSION || '',
  DATA_SERVICE_URI: process.env.REACT_APP_DATA_SERVICE_URI || '',
  STAGE: checkForUndefinedVar(process.env.REACT_APP_STAGE) || 'local',
  LOGIN_URL: process.env.REACT_APP_LOGIN_URL || '',
  LOGIN_REALM: process.env.REACT_APP_LOGIN_REALM || '',
  WEB_URL: checkForUndefinedVar(process.env.REACT_APP_WEB_URL) || '',
  SENTRY_DSN: checkForUndefinedVar(process.env.REACT_APP_SENTRY_DSN) || '',
  CSV_EXPORT_URL: checkForUndefinedVar(process.env.REACT_APP_CSV_EXPORT_URL) || '',
  LICENSE_SERVER_PUBLIC_KEY:
    '-----BEGIN PUBLIC KEY-----MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgGdv4oFuVEadgzDLmkhLNitE5DskQ36hlsTEkOQ77/fc2nIFPf4fLJoAio9eF03NBBc1tRE3DO5hJfHF+U5aDQ/U+7dryDqAQIQBMv1SQDkWhkwJyw2Hb+ixkUtJFQAptBtITiNqFPzco9MkMq/+PmMgh8OkThwCOeburXOxxj8TAgMBAAE=-----END PUBLIC KEY-----',
  API: {
    GQL_HTTP_URI: checkForUndefinedVar(process.env.REACT_APP_API_GQL_HTTP_URI) || '',
    GQL_HTTP_URI_USER_PROFILE_SERVICE:
      checkForUndefinedVar(process.env.REACT_APP_API_GQL_HTTP_URI_USER_PROFILE_SERVICE) || '',
    GQL_WS_URI: checkForUndefinedVar(process.env.REACT_APP_API_GQL_WS_URI) || '',
    GQL_SCHEMA_URI: checkForUndefinedVar(process.env.REACT_APP_API_GQL_SCHEMA_URI) || '',
  },
}
